import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import moment from 'moment';
import Loader from "../CommonLoader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import ErrorGWT from "./ErrorGWT";
import { Helmet } from 'react-helmet';
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { Commonfun } from './../Commonfun';
import { AppContext } from "../../../Context/Context";
const GoogleConsole=()=>{
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);
    const esodata = useSelector((state) => state);
    const ApiToken=esodata.apiauthkey.value;
    const [buttnval,setMenubutval]=useState('brand')
    const SevenDaysBefore= moment().subtract(7,'d').toDate();
    const [startDate, setStartDate] = useState(SevenDaysBefore);
    const [EndDate,SetEndDate]=useState(new Date());
    const [cardData, setCardData] = useState([]);
    const rows = cardData ?? [];
    const [loading, setLoading] = useState(false);
    const [ShowMainAlert,SetShowMainAlert]=useState(true)
    const [TableErrorMessage,SetTableErrorMessage]=useState('')
    const [autoheightval,SetAutoheightval]=useState(true)
    const [faqData,setfaqData]=useState("2")
    const [toggle, setToggle] = useState(false);
    const [initialfaqDateval,SetInitialfaqDateval] = useState([]);
    const [apiopen, setapiOpen] = React.useState(false);
    const [apierrorMsg,setapierrorMsg]=useState();
    const navigate = useNavigate();
    function roundToTwoDecimalPlaces(number) {
      return parseFloat(number.toFixed(2));
  }
const apihandleClose = () => {
    setapiOpen(false);
  };
    const columns = [
      {
        field: "sid",
        headerName: "#",
        width: 100,
        disableClickEventBubbling: true,
        headerAlign: "center",
        align: "center",
        description: 'Index of the row',
      },
  
      {
        field: "keys",
        headerName: "Search Query/Keyword",
        width: 400,
        headerAlign: "center",
        align: "center",
        sortable: true,
        disableClickEventBubbling: true,
        description:"The search terms or keywords through which the user has reached your website.",
        renderCell: (cardData) => {

   

        }
      },
      {
        field: "impressions",
        headerName: "Impressions",
        width: 300,
        headerAlign: "center",
        align: "center",
        sortable: true,
        disableClickEventBubbling: true,
        description:"It is counted each time your ad or webpage link is shown on a search result page.",

      },
      {
        field: "clicks",
        headerName: "Clicks",
        width: 200,
        headerAlign: "center",
        align: "center",
        sortable: false,
        disableClickEventBubbling: true,
        description:"Every click by the viewer on the website available on the organic search results is counted as a click.",

      },
      {
          field: "ctr",
          headerName: "CTR %",
          width: 200,
          headerAlign: "center",
          align: "center",
          disableClickEventBubbling: true,
          sortable: false,
          description:"It means the number of clicks that your website receives divided by the number of impressions of your website (clicks/impression).",

          renderCell: (cardData) => {

          var RoundedVal=0;
          RoundedVal=roundToTwoDecimalPlaces(cardData.row.ctr)
          return (
            <>{RoundedVal}%</>
          )

          }
        
        },
        {
          field: "position",
          headerName: "Average Position",
          width: 200,
          headerAlign: "center",
          align: "center",
          disableClickEventBubbling: true,
          sortable: false,
          description:"Average position (ranking) of the keywords that were added to the campaign through campaign settings.",

          renderCell: (cardData) => {

            var RoundedVal=0;
            RoundedVal=roundToTwoDecimalPlaces(cardData.row.position)
            return (
              <>{RoundedVal}</>
            )
  
            }
        
        },
    ];


    const StyledGridOverlay = styled("div")(({ theme }) => ({
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      "& .ant-empty-img-1": {
        fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
      },
      "& .ant-empty-img-2": {
        fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
      },
      "& .ant-empty-img-3": {
        fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
      },
      "& .ant-empty-img-4": {
        fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
      },
      "& .ant-empty-img-5": {
        fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
        fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
      },
    }));


    const MyCustomNoRowsOverlay = () => (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No Rows</Box>
      </StyledGridOverlay>
    );
    
    const menuChange=(e)=>{
      setMenubutval(e.target.id)
      GetAPIData(e.target.id)
      faqdatafun(e.target.id)
    }


    const GetAPIData=async(type='')=>{
      setCardData([])
      setLoading(true)
      SetShowMainAlert(false)
      SetTableErrorMessage('')
      SetTotalImpressions(0)
      SetTotalClicks(0)
      SetAverageCTR(0)
      SetAverageRank(0)
      var Passstart=moment(startDate).format('YYYY-MM-DD')
      var Passend=moment(EndDate).format('YYYY-MM-DD')
      try{

        const datavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}googleanalytics/v2/brandvsnonbrand_table/?user_domain_id=${esodata.domaindata.value.header_selected}&start_date=${Passstart}&end_date=${Passend}&keyword_type=${type}`,{
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
            navigate('/errorpages')    
          }
          // console.log("kannan",error)
        });

        if(datavalue?.data?.queries)
        {
          SetTotalImpressions(datavalue.data.queries.impressions)
          SetTotalClicks(datavalue.data.queries.clicks)
          SetAverageCTR(datavalue.data.queries.ctr+'%')
          SetAverageRank(datavalue.data.queries.rank)
        }

        if(datavalue.data.queries)
        {
         if(datavalue.data.queries.keywords)
         {

          if(datavalue.data.queries.keywords.length>0)
          {
             const newData = datavalue.data.queries.keywords.map((obj, index) => {
              return { ...obj, sid: index + 1 };
            });
    
            setCardData(newData);
            SetAutoheightval(false)
  
            
          }
          else{
            var ErrorMessage;
         
            if(type=='brand')
            {
              ErrorMessage='Branded keywords not available for this domain'
            }
            else if(type=='non-brand')
            {
              ErrorMessage='Non-branded keywords not available for this domain'
            }
            SetTableErrorMessage(ErrorMessage)
            SetAutoheightval(true)
          }

         }
        

        }

       

        if(datavalue.data.status_code)
        {
          SetShowMainAlert(true)
       
        }

      }catch(err)
      {
        setLoading(false)
     
       if(err.response)
       {

        if(err.response.data)
        {

          SetTableErrorMessage(err.response.data.error)
        }
        else{

          SetTableErrorMessage('')
        }

       }
       else{
        SetTableErrorMessage('')
       }
       
      
      }

       

      setLoading(false)
      
    }

    const [BrandPercentage,SetBrandPercentage]=useState(0)
    const [BrandLabel,SetBrandLabel]=useState('')
    const [NonBrandPercentage,SetNonBrandPercentage]=useState(0)
    const [NonBrandLabel,SetNonBrandLabel]=useState('')
    const [ChartType,SetChartType]=useState('impressions')
    const [TotalImpressions,SetTotalImpressions]=useState(0)
    const [TotalClicks,SetTotalClicks]=useState(0)
    const [AverageCTR,SetAverageCTR]=useState(0)
    const [AverageRank,SetAverageRank]=useState(0)
    const data = [
      { value: BrandPercentage, label: BrandLabel },
      { value: NonBrandPercentage, label: NonBrandLabel },
  
    ];
    
    const size = {
   
      height: 350,
    };
    
    const StyledText = styled('text')(({ theme }) => ({
      fill: theme.palette.text.primary,
      textAnchor: 'middle',
      dominantBaseline: 'central',
      fontSize: 20,
    }));
    
    function PieCenterLabel({ children }) {
      const { width, height, left, top } = useDrawingArea();
      return (
        <StyledText x={left + width / 2} y={top + height / 2}>
          {children}
        </StyledText>
      );
    }

    const GetGraphdata=async(type='')=>{

      setLoading(true)

      SetBrandPercentage(0)
      SetNonBrandPercentage(0)


      var Passstart=moment(startDate).format('YYYY-MM-DD')
      var Passend=moment(EndDate).format('YYYY-MM-DD')

      try{

        const datavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}googleanalytics/v2/brandvsnonbrand_graph/?user_domain_id=${esodata.domaindata.value.header_selected}&start_date=${Passstart}&end_date=${Passend}&chart_option=${type}`,{
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
            navigate('/errorpages')    
          }
          // console.log("kannan",error)
        });
        setLoading(false)
  
        if(datavalue?.data)
        {
          if(datavalue?.data?.chart_data)
          {
            SetChartType(datavalue.data.chart_data.chart_option)
            SetBrandPercentage(datavalue.data.chart_data.brand_percentage)
            SetNonBrandPercentage(datavalue.data.chart_data.non_brand_percentage)
            SetBrandLabel('Branded('+datavalue.data.chart_data.chart_option+')-'+datavalue.data.chart_data.brand_percentage+'%'+' ('+datavalue.data.chart_data.brand_value+')')
            SetNonBrandLabel('Non Branded('+datavalue.data.chart_data.chart_option+')-'+datavalue.data.chart_data.non_brand_percentage+'%'+' ('+datavalue.data.chart_data.non_brand_value+')')
          }
        }

      }catch(err)
      {
        setLoading(false)
        console.log(err)
      }


      

   


      
    }

    useEffect(()=>{
        GetAPIData('brand')
        GetGraphdata('impressions')
        faqdatafun()
    },[])

    const SubmitData=()=>{
      GetAPIData(buttnval)
      GetGraphdata(ChartType)
    }


    const CheckPieRender=(brandpercentage,nonbrandpercentage)=>{

      if(brandpercentage==0 && nonbrandpercentage==0)
      {
        return false
      }
      else{
        return true
      }

    
      
    }

    const SetChartTypeTrigger=(value)=>{
      SetChartType(value)
      GetGraphdata(value)


    }
    const pdfdown = async () =>{ 
      if(buttnval=="brand")
      var keytype="brand";
      else if(buttnval=="non-brand")
      var keytype="non-brand";
      var Passstart=moment(startDate).format('YYYY-MM-DD')
      var Passend=moment(EndDate).format('YYYY-MM-DD')
      setLoading(true);
      try{
      const response = await fetch(`${process.env.REACT_APP_API_URL}googleanalytics/v2/brandvsnonbrand_table/?user_domain_id=${esodata.domaindata.value.header_selected}&start_date=${Passstart}&end_date=${Passend}&keyword_type=${keytype}&chart_option=${ChartType}&formats=pdf`, {
                  method: 'GET',
                  headers: {
                      'Content-Type': 'application/pdf',
                      Authorization: `${ApiToken}`,
                  },
              });
              // Check if the request was successful (status code 200)
              if (response.ok) {
                  // Convert the response to a blob
                  const blob = await response.blob();
  
                  // Create a temporary anchor element
                  const link = document.createElement('a');
                  link.href = window.URL.createObjectURL(blob);
                
                  var filedata=await Commonfun("Branded vs Non Branded","pdf", ApiToken,esodata.domaindata.value.header_selected);

                  link.download = filedata;
                  link.click();
                  // Remove the anchor element
                  window.URL.revokeObjectURL(link.href);
              } else {
                if(response?.status==599 || response?.status==500 || response?.status==400  || response?.status==404 ){
                  response.json().then(data => {
                    setapiOpen(true);
                    setapierrorMsg(data?.error)
                    console.log('Resolved object:', data.error);
                  })
                }
                else if(response?.code =="ERR_NETWORK"){
                  navigate('/errorpages')    
                }
              }
          } catch (error) {
            toast.error("No Data", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
            
              console.error('Error during PDF download:', error);
          }
          setLoading(false);
  
    }
    const csvdown = async () =>{ 
      var Passstart=moment(startDate).format('YYYY-MM-DD')
      var Passend=moment(EndDate).format('YYYY-MM-DD')
      setLoading(true);
      try{
      const response = await fetch(`${process.env.REACT_APP_API_URL}googleanalytics/v2/brandvsnonbrand_table/?user_domain_id=${esodata.domaindata.value.header_selected}&start_date=${Passstart}&end_date=${Passend}&keyword_type=brand&chart_option=${ChartType}&formats=csv`, {
                  method: 'GET',
                  headers: {
                      'Content-Type': 'application/pdf',
                      Authorization: `${ApiToken}`,
                  },
              });
              // Check if the request was successful (status code 200)
              if (response.ok) {
                  // Convert the response to a blob
                  const blob = await response.blob();
  
                  // Create a temporary anchor element
                  const link = document.createElement('a');
                  link.href = window.URL.createObjectURL(blob);
                  var filedata=await Commonfun("Branded vs Non Branded","csv", ApiToken,esodata.domaindata.value.header_selected);

                  link.download = filedata;
                  link.click();
  
                  // Remove the anchor element
                  window.URL.revokeObjectURL(link.href);
              } else {
                if(response?.status==599 || response?.status==500 || response?.status==400  || response?.status==404 ){
                  response.json().then(data => {
                    setapiOpen(true);
                    setapierrorMsg(data?.error)
                    console.log('Resolved object:', data.error);
                  })
                }
                else if(response?.code =="ERR_NETWORK"){
                  navigate('/errorpages')    
                }
              }
          } catch (error) {
            toast.error("No Data", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
            
              console.error('Error during PDF download:', error);
          }
          setLoading(false);
  
    }
    const e = document.getElementById('root');
    e.addEventListener('click', function handleClick(event) {
      console.log(event.target.classList[0])
      if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') || 
      (event.target.classList[0] === 'nav_key_txt') || 
      (event.target.classList[0] === 'cnt_hdr_top_wrap') ||(event.target.classList[0] === 'react-dropdown-select') 
      ||(event.target.classList[0] === 'react-dropdown-select-content') 
      ||(event.target.classList[0] === 'react-dropdown-select-item') 
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap')   || (event.target.classList[0] === 'App')  
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
       || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row') 
       || (event.target.classList[0] === 'MuiDataGrid-cell')  || (event.target.classList[0] === 'MuiDataGrid-cellContent')
       || (event.target.classList[0] === 'header_inner_btm_wrap')||(event.target.classList[0] === 'over_all_container') 
       ||(event.target.classList[0] === 'input_domain_field') ||(event.target.classList[0] === 'react-dropdown-select-input')
       || (event.target.classList[0] === 'run_rpt_btn')  || (event.target.classList[0] === 'input_col') || (event.target.classList[0] === 'plagarism_analyzer_row')
       || (event.target.classList[0] === 'MuiDataGrid-cell--withRenderer') || (event.target.classList[0] === 'daterangepicker_wrap')
       || (event.target.classList[0] === 'site_rn_rpt_btn') ||(event.target.classList[0] === 'react-datepicker-ignore-onclickoutside')      
      ||(event.target.classList[0] === 'cnt_audit_outer_wrap') ||(event.target.classList[0] === 'wrap_trans_back') 
      ) {
       
        setfaqData(2)
        setToggle(false)   
      }
    });
    const faqdatafun = async(menubutval="") => {
      if(menubutval=="brand" ||menubutval=="" )
      var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=37`
    else if(menubutval=="non-brand")
    var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=38`
    
      try {
        const doaminsectiondatavalue = await axios.get(
          datavar,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
            navigate('/errorpages')    
          }
          // console.log("kannan",error)
        });
        if (doaminsectiondatavalue?.data) {
         console.log("domain data",doaminsectiondatavalue?.data?.faqs)
          SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
        }
      } catch (err) {}
    }
    const handleToggle = () => {
      if(faqData!=1){
        setfaqData(1)
      }
      else{
        setfaqData(2)
      }
          setToggle((pre) => !pre);
    };

    return (
      <div className={`over_all_container brand_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`} >
         <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
         <Helmet>
                <meta charSet="utf-8" />
                <title>Branded VS Non-Branded</title>
            </Helmet>

<div className="cnt_audit_outer_wrap">

<div className="cnt_hdr_top_wrap">
        <div className="cnt_hdr_top_lft_wrap">
          <h2>Branded Vs Non Branded</h2>

        </div>
        <div className="cnt_hdr_top_rgt_wrap">
        <div className={`App ${faqData=="1"?'faq_move_class':""}`} >
            <button onClick={handleToggle} className="faq_class"  title="Frequently Asked Question">
            <QuestionMarkIcon />
              
            </button>
           {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />} 
          </div>
        <img src={pdficon} alt="pdf icon" onClick={pdfdown}/>
         <img src={csvicon} alt="csv icon" onClick={csvdown} /> 
        </div>
      </div>

         {
      loading===true ?   <Loader/> : null
    }
       <div className="wrap_trans_back">

       <div className="daterangepicker_wrap">
        <DatePicker selected={startDate}  onChange={(date) => setStartDate(date)} />
        <DatePicker selected={EndDate}  onChange={(date) => SetEndDate(date)} />
      
        <button className="site_rn_rpt_btn daterangepicker_wrap_submit" onClick={()=>{SubmitData()}}>Submit</button>

        <select  className="site_rn_rpt_btn" onChange={(e)=>{SetChartTypeTrigger(e.target.value)}}>
          <option value={'impressions'}>Impressions</option>
          <option value={'clicks'}>Clicks</option>
          <option value={'ctr '}>CTR</option>
          <option value={'rank'}>Rank</option>
          <option value={'ranked-terms'}>Ranked terms</option>
          <option value={'1st-position'}>1st Position</option>
          <option value={'1st-page'}>1st Page</option>
        </select>

        </div>

       </div>

        

        

     

        {

      (ShowMainAlert===false && CheckPieRender(BrandPercentage,NonBrandPercentage)) ?  <PieChart series={[{ data, innerRadius: 120 ,outerRadius: 141 }]} {...size}>
      <PieCenterLabel>Brand Vs Non-Brand </PieCenterLabel>
    </PieChart> : null

        }


<div className="site_cnt_hdr_blw_wrap">
        <button  type="button" id='brand'  className= {`site_rn_rpt_btn ${buttnval==="brand"?'active':""}`} onClick={e => menuChange(e)} >Branded Keyword</button>
        <button  type="button"  id='non-brand' className={`site_rn_rpt_btn ${buttnval==="non-brand"?'active':""}`}  onClick={e => menuChange(e)} >Non Branded Keyword</button>
        </div>


{
  ShowMainAlert===false ? <div className="external_link_data_row google_branded_row">
  <div className="cols ranking_summary_col" data-id="" >
    
    <div className="content_col">
      <p>Total Impressions</p>
      <p>{TotalImpressions}</p>
    </div>
  </div>
  <div className="cols ranking_summary_col" data-id="">
   
    <div className="content_col" >
      <p>Total Clicks</p>
      <p>{TotalClicks}</p>
    </div>
  </div>
  <div className="cols ranking_summary_col" data-id="first_page_rank">
   
    <div className="content_col" >
      <p>Avg CTR</p>
      <p>{AverageCTR}</p>
    </div>
  </div>
  <div className="cols ranking_summary_col" data-id="visibility_score" >
    
    <div className="content_col" >
      <p>Average Rank</p>
      <p>{AverageRank}</p>
    </div>
  </div>
</div> : null
}


       {
        <div className="table_error_message">{TableErrorMessage}</div>
       }

{
  (ShowMainAlert===false && cardData.length>0 ) ? 
  <div className="data_table brand_list_table brand_google_analytics_table" style={{ width: "100%" }}>
  <div style={{ height: 550, width: "100%" }}>
    <DataGrid
      getRowId={(row) => row.sid}
      slots={{
        noRowsOverlay: MyCustomNoRowsOverlay,
        toolbar: GridToolbar
      }}
      slotProps={{
        toolbar: {
      
          showQuickFilter: true,
        
        },
      }}
      rows={rows}
      columns={columns}
      sx={{
        "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
          py: "8px",
        },
        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
          py: "15px",
        },
        "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
          py: "22px",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          whiteSpace: "normal",
          lineHeight: "normal",
        },
        "&, [class^=MuiDataGrid]": { border: "none" },
        ".MuiDataGrid-virtualScroller": {
          height: "auto !important",
          overflowY: "auto",
        },
        "& .MuiDataGrid-cell:first-child": {
          position: "sticky",
          top: "0",
          left: "0",
          paddingLeft: "1.5rem",
          zIndex: 999,
        },
        "& .MuiDataGrid-columnHeader:first-child": {
          position: "sticky",
          top: "0",
          left: "0",
          paddingLeft: "1.5rem",
          border: "none",
          zIndex: 999,
        },
      }}
      getRowHeight={() => "auto"}
      autoHeight={autoheightval?true:false}  
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      loading={loading}
      
      pageSizeOptions={[10, 30, 50, 75, 100]}
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
    />
  </div>
</div> : null
}

{
  ShowMainAlert===true ? 
  
 <ErrorGWT/>  : null
}
       




</div>
<Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
        </div>
    )

}
export default GoogleConsole