import React, { useState, useEffect ,useContext} from "react";
import { styled, useTheme } from "@mui/material/styles";
import "./Missedstyle.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "react-dropdown-select";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import Loader from "../CommonLoader";
import { ChartContainer, BarPlot } from "@mui/x-charts";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpSharpIcon from '@mui/icons-material/ArrowDropUpSharp';
import SquareIcon from '@mui/icons-material/Square';
import GooglePng from "../../../Icons/google.png";
import BingPng from "../../../Icons/bing.png";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Keywordbar from "./../Rightsidebar/Keywordbar";
import { useNavigate } from "react-router-dom";
import { Commonfun } from './../Commonfun';
import { AppContext } from "../../../Context/Context";

export default function Missedopportunity() {
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState([]);
  const [selectjobid, setselectjobid] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectval, setSelectval] = useState([]);
  const [selectsecondval, setSelectsecondval] = useState([]);
  const [menubutval,setMenubutval]=useState("1")
  const [buttnval,setButtnval]=useState("1")
  const [pagename,setPagename]=useState("Missed Opportunities")
  const [sitecardData, setSitecardData] = useState([]);
  const [internalcardData, setInternalcardData] = useState([]);
  const [seoindexData, setSeoindexData] = useState([]);
  const [seooccurData, setSeooccurData] = useState([]);
  const [latestoccurData, setLatestoccurData] = useState([]);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [value, setValue] = useState("1");
  const [chartvalue, setChartvalue] = useState("4");
  const [domainval,setDomainval]=useState([])
  const [reportdata, setReportdata] = useState("");
  const [reporttwodata, setReporttwodata] = useState([]);
  const [latestdataarr, setLatestdataarr] = useState([]);
  const [earlierdataarr, setEarlierdataarr] = useState([]);
  const [arrheaderdata, setArrheaderdata] = useState([]);
  const [serveritydata, setServeritydata] = useState([]);
  const [SearchEngine, SetSearchEngine] = useState("");
  const [Countryoptions, SetCountryoptions] = useState([]);
  const [Stateoptions, SetStateoptions] = useState([]);
  const [CityOptions, SetCityOptions] = useState([]);
  const [Engineoptions,SetEngineoptions]=useState([]);
  const [Country, SetCountry] = useState("");
  const [State, SetState] = useState("");
  const [City, SetCity] = useState("");
  const [statedataid, SetStatedataid] = useState("");
  const [citydataid, SetCitydataid] = useState("");
  const [autoheightval,SetAutoheightval]=useState(true)



  const [AddDataTableErr, SetAddDataTableErr] = useState("");
  const [AddDataTableArray, SetAddDataTableArray] = useState([]);
  const [DoaminSectionUrl,SetDoaminSectionUrl]=useState([]);
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [campaign, Setcampaign] = useState("");
  const [campaignoption, Setcampaignoption] = useState([]);
  const [ErrorMsg, SetErrorMsg] = useState("");
  const esodata = useSelector((state) => state);
  const ApiToken=esodata.apiauthkey.value;

  const [dcampaignoption, Setdcampaignoption] = useState([]);
  const [dselectjobid,setdselectjobid]=useState([])
  const [dSearchEngine,SetdSearchEngine]=useState([])
  const [dCountryHandle,SetdCountryHandle]=useState([])
  const [dStateHandle,SetdStateHandle]=useState([])
  const [dCity,SetdCity]=useState([])
  const [toggle, setToggle] = useState(false);
  const [faqData,setfaqData]=useState("2")
  const [initialDateval, SetInitialDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();
  
  const navigate = useNavigate();

  const apihandleClose = () => {
    setapiOpen(false);
  };
  const columns= [

    {
      field: "id",
      headerName: "#",
      description:'Index of the row',
      width: 100,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",      
    },
 
    {
      field: "keyword",
      headerName: "Keywords",
      description:'The list of keywords is provided in campaign settings.',
      width: 170,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",      
    },
    {
      field: "search_voume",
      headerName: "SV",
      description:"A keyword's total search volume in search results.",
      width: 70,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: "Monthly Searches",
      headerName: "SV Trend",
      description:"The last 12 months' search volume is shown visually as a simple small bar graph for the keyword. ",
      width: 200,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (cardData) => {
        var sr = cardData.row.sv_trend.last_month_search_volume;
        if (sr != null && Array.isArray(sr) && sr.length > 0) {
          // const numAscending = sr.sort((a, b) => a.month - b.month);
          var monthval = sr.map((fruit) => fruit.month);
           var chartdataval = sr.map((fruit) => fruit.search_volume);
          return (
            <ChartContainer
              width={200}
              height={150}
              series={[{ data: chartdataval, label: "uv", type: "bar" }]}
               xAxis={[{ scaleType: "band", data: monthval }]}
            >
              <BarPlot />
            </ChartContainer>
          );
        }else{
          return sr;
        }
      },
    },
    {
      field: "difficulty",
      headerName: "Difficulty",
      description:"The Value-Based Display Of Keyword Difficulty For The Respective Keyword",
      width: 150,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (cardData) => {
        var sr = cardData.row.difficulty.value;
        return (sr);
      },
    },
    {
      field: "category",
      headerName: "Campaign",
      description:"Ranking Position For The Keyword In Search Results",
      width: 150,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: "search_engine",
      headerName: "Search Engine",
      description:"List Of Search Engine Configured In The Campaign Settings",
      width: 110,
      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {


        if(cardData.row.search_engine==='Bing')
        {

          return(

            <div className="search_engine_row">
            <img src={BingPng}/>{cardData.row.search_engine}
          
            </div>
 
         )

        }
        else if (cardData.row.search_engine==='Google')
        {

          return(

            <div className="search_engine_row">
               <img src={GooglePng}/>{cardData.row.search_engine}
            </div>
 
         )

        }
        else{

          return(

          
            cardData.row.search_engine
           
 
         )

        }
         
      }
    },
    {
      field: "location",
      headerName: "Location",
      description:"The list of locations is configured in the campaign settings.",
      width: 230,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (cardData) => {

        return(

           <>
           

           <div> {cardData.row.location.country} {cardData.row.location.state ? `,` : ""}<br/>
         {cardData.row.location.state }{cardData.row.location.city ? `,` : ""}<br/>
          {cardData.row.location.city ? `${cardData.row.location.city}` : ""}</div>
           </>

        )
    
      }
    },
    {
      field: "position",
      headerName: "Position",
      description:"Ranking Position For The Keyword In Search Results",
      width: 120,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: "missed_val",
      headerName: "Missed Opportunity",
      description:"Missed Opportunity Of The Keywords For The Website",
      width: 130,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: "change_in_percent",
      headerName: "Change In Percent",
      description:"The Change In Percent For Missed Opportunity For The Keyword Comparing With The Previous Report",
      width: 130,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (cardData) => {
       
        if(cardData.row.change_in_percent.change && cardData.row.change_in_percent.change==='Dropped')
        {
          return(

            <div className="gain_drop_row" style={{color:'#ffa6a6'}}>
            <ArrowDropDownIcon/>
            <span>{cardData.row.change_in_percent.value}</span>
            </div>
 
         )

        }

        else if(cardData.row.change_in_percent.change && cardData.row.change_in_percent.change==='Gained')
        {
          

          return(

            <div className="gain_drop_row" style={{color:'#00ab00'}}>
            <ArrowDropUpSharpIcon/>
           
            <span>{cardData.row.change_in_percent.value}%</span>
            </div>
 
         )

        }

        else if(cardData.row.change_in_percent.change && cardData.row.change_in_percent.change==='No Change')
        {

          return(

            <div className="gain_drop_row no_change_row" style={{color:'orange'}}>
               <SquareIcon/>
            
            <span>N/A</span>
            </div>
 
         )

        }
        else{
          return(
            <div>            
            <span>{cardData.row.change_in_percent}%</span>
            </div>
 
         )
        }
      },
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handlechartChange = (event, newValue) => {
    setChartvalue(newValue);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );

  const SetCountryHandle = async (value) => {
    SetStateoptions([]);
    SetdStateHandle([])
    SetCity("");
    if (value) {
      SetCountry(value.value);
      try{
        const statedatavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${selectjobid}&user_domain_id=${esodata.domaindata.value.header_selected}&campaign_id=${campaign}&search_engine_id=${SearchEngine}&country_id=${value.value}&filter_type=state`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
            navigate('/errorpages')    
          }
          // console.log("kannan",error)
        });
        if (statedatavalue?.data) {
          if (statedatavalue?.data?.states) {
            var Sections = [];

            statedatavalue.data.states.forEach((element) => {
              Sections.push({ label: element.search_engine_locale__state__state_name, value: element.search_engine_locale__state__id });
            });
          
            SetStateoptions(Sections);
            SetdStateHandle(Sections[0])
          }
        }

      }catch(err)
      {
        console.log(err)
      }

   
    }
  };

  const SetStateHandle = async (value) => {
    SetCityOptions([]);
    SetdCity([])
    SetCity("");

    if (value && value.value !== undefined) {
      console.log("kannaval",value)
      SetState(value);
      console.log("kannanstatecountry",Country)
      const citydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${selectjobid}&user_domain_id=${esodata.domaindata.value.header_selected}&campaign_id=${campaign}&search_engine_id=${SearchEngine}&country_id=${Country}&state_id=${value.value}&filter_type=city`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
         
      });

      if (citydatavalue?.data) {
        if (citydatavalue?.data?.cities) {
          var Sections = [];
          citydatavalue.data.cities.forEach((element) => {
            Sections.push({ label: element.search_engine_locale__city__city_name, value: element.search_engine_locale__city__id });
          });
          SetCityOptions(Sections);
          SetdCity(Sections[0])
        }
      }
    }
    else{
      SetState(0);
    }
  };


  const rows = cardData ?? [];
  const finalreportNow = async () => {
    setLoading(true);
    SetErrorMsg("")

    var StateId='';
    var CityId='';

console.log("Country",Country)
    
    if(State.value)
    {
      StateId=State.value
      SetStatedataid(StateId)
    
    }
    else{
      StateId=0
      SetStatedataid(0)
    }
    if(City.value)
    {
      CityId=City.value
      SetCitydataid(CityId)
    }
    else{
      CityId=0
      SetCitydataid(0)
    }




if(selectjobid!=="" || campaign!=="" || SearchEngine!=="" || Country!=="" || StateId!=="" || CityId!==""  ){
    const datavalue = await axios
    .get(
      `${process.env.REACT_APP_API_URL}keywords/v2/missed_opportunity_table/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${selectjobid}&campaign_id=${campaign}&search_engine_id=${SearchEngine}&country_id=${Country}&state_id=${StateId}&city_id=${CityId}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    )
    .catch((error) => {
      if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
        navigate('/errorpages')    
      }
      // console.log("kannan",error)
    }); 
    
      if(datavalue?.data)
      {
        const dataval = datavalue?.data?.missed_opportunity;
    
    setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));
        const newData = dataval.map((obj, index) => {
          return { ...obj, id: index+1 };
        });
        setCardData(newData);
  
     } 
     else{
      setCardData("");
     }
    
    setLoading(false);
    
}
else{
  // setLoading(false);
  // return SetErrorMsg("Please Select the All Fields !");

}
       closeModal();
      setLoading(false);
  }
  
 const reportNow = async () => {
    setLoading(true);
    var errs = 1;
    let errors = {};
    if (selectval.length === 0 || selectsecondval.length === 0 ) {
      errors.dateerror = "Please Select Job id.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
     
    }
    if (errs === 2) {
      setLoading(false);
      return setValidation(errors);
    }
    setValidation(errors);
    callUserdataApi(selectval[0].id,selectsecondval[0].id)
    // closeModal();
    setLoading(false);
  };
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    // setselectjobid('')
    // Setcampaign('')
    // SetSearchEngine('')
    // SetCountry('')
    // SetState('')
    // SetCity('')
    setIsOpen(false);
  }
  function dataChange(e){
    let dataval_id=e[0].id
    let fun_name=1
    calljobiddata(dataval_id,fun_name)
  }
  function datavalChange(e){
    let dataval_id=e[0].id;
    let fun_name=2
    calljobiddata(dataval_id,fun_name)
  }
  const calljobiddata = async (dataval_id ="",fun_name ="")=>{
    setLoading(true);
    const datedatavalue = await axios
      .get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=site_audit&user_domain_id=${dataval_id}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      )
      .catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      setLoading(false)
      if(fun_name==1)
      setReportdata(datedatavalue.data.jobs)
      else if(fun_name==2)
      setReporttwodata(datedatavalue.data.jobs)
  }
  useEffect(() => {
   
      callUserdataApi();
      LoadAddFormFilterOptions();
      faqdatafun();
  }, []);
  const LoadAddFormFilterOptions = async (jobid = "") => {
    setjobidData([]);
    SetSectionOptions([]);
    SetCountryoptions([]);
    SetEngineoptions([]);
    Setcampaignoption([])
    try {
      const campaigndatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${selectjobid}&user_domain_id=${esodata.domaindata.value.header_selected}&search_engine_id=${SearchEngine}&country_id=${Country}&state_id=${State}&city_id=${City}&filter_type=campaign`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      
      if (campaigndatavalue?.data?.campaigns) {
        if (campaigndatavalue?.data?.campaigns) {
          var campaignSections = [];
          campaigndatavalue.data?.campaigns.forEach((element) => {
            campaignSections.push({ label: element.category__name, value: element.category__id });
          });
          Setcampaignoption(campaignSections);
          Setdcampaignoption(campaignSections[0])
        }
      }
    } catch (err) {}
    try {
      const jobdatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?user_domain_id=${esodata.domaindata.value.header_selected}&job_type=rankings_summary`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      if (jobdatavalue?.data?.jobs) {
        if (jobdatavalue?.data?.jobs) {
          var jobSections = [];
          jobdatavalue.data.jobs.forEach((element) => {
            jobSections.push({ label: element.completed_at, value: element.id });
          });
          if(jobSections.length>0)
          setdselectjobid(jobSections[0])
          setjobidData(jobSections);
        }
      }
    } catch (err) {}
    try {
      const enginedatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${jobid}&user_domain_id=${esodata.domaindata.value.header_selected}&campaign_id=${campaign}&country_id=${Country}&state_id=${State}&city_id=${City}&filter_type=search_engine`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      if (enginedatavalue?.data?.search_engines) {
        if (enginedatavalue?.data?.search_engines) {
          var Sections = [];
          enginedatavalue.data.search_engines.forEach((element) => {
            Sections.push({ label: element.search_engine_locale__search_engine__name, value: element.search_engine_locale__search_engine__id });
          });
          SetEngineoptions(Sections);
          SetdSearchEngine(Sections[0])
        }
      }
    } catch (err) {}
    try {
      const countrydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${jobid}&user_domain_id=${esodata.domaindata.value.header_selected}&campaign_id=${campaign}&search_engine_id=${SearchEngine}&state_id=${State}&city_id=${City}&filter_type=country`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      if (countrydatavalue?.data?.countries) {
        if (countrydatavalue?.data?.countries) {
          console.log("countrydatavalue",countrydatavalue.data.countries)
          var Sections = [];
          countrydatavalue.data.countries.forEach((element) => {
            Sections.push({ label: element.search_engine_locale__country__name, value: element.search_engine_locale__country__id });
          });
          SetCountryoptions(Sections);
          SetdCountryHandle(Sections[0])
          SetdStateHandle(Sections[0])
          SetdCity(Sections[0])
        }
      }
    } catch (err) {}



  };
  const callUserdataApi =  async (jobid = "",secondjobid ="") => {
    setLoading(true);
    const datavalue = await axios
      .get(
        `${process.env.REACT_APP_API_URL}keywords/v2/missed_opportunity_table/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      )
      .catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });     
    

      if(datavalue?.data?.missed_opportunity && datavalue?.data?.missed_opportunity.length>0)
      {
        const dataval = datavalue?.data?.missed_opportunity;
   setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));
        SetAutoheightval(false)
        const newData = dataval.map((obj, index) => {
          return { ...obj, id: index+1 };
        });
        setCardData(newData);
  
     } 
     else{
      setCardData("");
      SetAutoheightval(true)
    }
    
    setLoading(false);
  };
  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const pdfdown = async () =>{ 

    setLoading(true);
    try{
    const response = await fetch(`${process.env.REACT_APP_API_URL}keywords/v2/missed_opportunity_table/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${selectjobid}&campaign_id=${campaign}&search_engine_id=${SearchEngine}&country_id=${Country}&state_id=${statedataid}&city_id=${citydataid}&formats=pdf`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();
  
                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
              
                var filedata=await Commonfun("MissedOpportunities","pdf", ApiToken,esodata.domaindata.value.header_selected);
                // return false;
                link.download = filedata;
                link.click();
                
  
                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              if(response?.status==599 || response?.status==500 || response?.status==400  || response?.status==404 ){
                response.json().then(data => {
                  setapiOpen(true);
                  setapierrorMsg(data?.error)
                  console.log('Resolved object:', data.error);
                })
              }
              else if(response?.code =="ERR_NETWORK"){
                navigate('/errorpages')    
              }
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);
  
  }
  const csvdown = async () =>{ 
    setLoading(true);
    try{
      const response = await fetch(`${process.env.REACT_APP_API_URL}keywords/v2/missed_opportunity_table/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${selectjobid}&campaign_id=${campaign}&search_engine_id=${SearchEngine}&country_id=${Country}&state_id=${statedataid}&city_id=${citydataid}&formats=csv`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();
  
                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                var filedata=await Commonfun("MissedOpportunities","csv", ApiToken,esodata.domaindata.value.header_selected);
                // return false;
                link.download = filedata;
                link.click();
                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              if(response?.status==599 || response?.status==500 || response?.status==400  || response?.status==404 ){
                response.json().then(data => {
                  setapiOpen(true);
                  setapierrorMsg(data?.error)
                  console.log('Resolved object:', data.error);
                })
              }
              else if(response?.code =="ERR_NETWORK"){
                navigate('/errorpages')    
              }
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);
  }
  const faqdatafun = async(menubutval="") => {
    var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=10`
  
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {
       console.log(doaminsectiondatavalue?.data)
        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) {}
  }
  const handleToggle = () => {
    if(faqData!=1){
      setfaqData(1)
    }
    else{
      setfaqData(2)
    }
        setToggle((pre) => !pre);
  };
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') || 
    (event.target.classList[0] === 'nav_key_txt') || 
    (event.target.classList[0] === 'cnt_hdr_top_wrap') ||(event.target.classList[0] === 'react-dropdown-select') 
    ||(event.target.classList[0] === 'react-dropdown-select-content') 
    ||(event.target.classList[0] === 'react-dropdown-select-item') 
    || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap')   || (event.target.classList[0] === 'App')  
    || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
     || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row') 
     || (event.target.classList[0] === 'MuiDataGrid-cell')  || (event.target.classList[0] === 'MuiDataGrid-cellContent')
     || (event.target.classList[0] === 'header_inner_btm_wrap')||(event.target.classList[0] === 'over_all_container') 
     ||(event.target.classList[0] === 'input_domain_field') ||(event.target.classList[0] === 'react-dropdown-select-input') ) {
      setfaqData(2)
      setToggle(false)   
    }
});
  return (

    
   
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
         <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
          <Helmet>
                <meta charSet="utf-8" />
                <title>Missed Opportunities</title>
            </Helmet>
          {/* {
      loading===true ?   <Loader/> : null
    } */}
  
    <div className="cnt_audit_outer_wrap">
      <div className="cnt_hdr_top_wrap">
        <div className="cnt_hdr_top_lft_wrap">
         <h2> {pagename}</h2>
         <p>as of {jobDate}</p>
        </div>
        <div className="cnt_hdr_top_rgt_wrap">
        <div className={`App ${faqData=="1"?'faq_move_class':""}`} >
            <button onClick={handleToggle} className="faq_class"  title="Frequently Asked Question">
            <QuestionMarkIcon />
              
            </button>
           {toggle && <Keywordbar close={() => setToggle(false)} initialDateval={initialDateval} />} 
          </div>
        <img src={pdficon} alt="pdf icon" onClick={pdfdown}/>
          <img src={csvicon} alt="csv icon" onClick={csvdown} />
        </div>
      </div>
      <div className="site_blw_wrap regressiontest">
        <div className="cnt_hdr_blw_wrap">
          {/* <button className="run_rpt_btn rn_rpt_btn" onClick={reportNow}>
            Run Report Now
          </button> */}
          <button className="run_rpt_btn ch_date_btn" onClick={openModal}>
            Filter By
            <span className="_btn_icon_calender">
              <img src={calendarpng} alt="calender logo" />
            </span>
          </button>
        </div>
      </div>
      <div className="data_table missed_oppurtunites_table" style={{ width: "100%" }}>
              <div style={{ height:550, width: "100%" }}>
                <DataGrid
                      slots={{
                        noRowsOverlay: MyCustomNoRowsOverlay,
                        toolbar: GridToolbar
                      }}
                      slotProps={{
                        toolbar: {
                      
                          showQuickFilter: true,
                        
                        },
                      }}
                  rows={rows}
                  columns={columns}
                  sx={{
                    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                      py: "8px",
                    },
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                      py: "15px",
                    },
                    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
                      {
                        py: "22px",
                      },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                    },
                    "&, [class^=MuiDataGrid]": { border: "none" },
                    ".MuiDataGrid-virtualScroller": {
                      height: "auto !important",
                      overflowY: "auto",
                    },
                    "& .MuiDataGrid-cell:first-child": {
                      position: "sticky",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      zIndex: 999,
                    },
                    "& .MuiDataGrid-columnHeader:first-child": {
                      position: "sticky",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      border: "none",
                      zIndex: 999,
                    },
                  }}
                  gridRowId={(row) => row.id}
                  getRowHeight={() => "auto"}
                  autoHeight={autoheightval?true:false} 
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10, 30, 50, 75, 100]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  loading={loading}                
                />
              </div>
            </div>
      </div>
     
    <div className="missed_below_note_ranking">
      <div className="below_note_ranking">
      *Note  :   N/A is Not Available

      </div>
      <div className="below_note_ranking icon">
      <div className="green">
      <ArrowDropUpSharpIcon/>-  Gained 
      </div>
    
    <div className="rose">
    <ArrowDropDownIcon/> -   Dropped 
    </div>

    <div className="orange">
    <SquareIcon/>-  No Change
    </div>
    

      </div>
      </div>
     

      <Dialog
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter"
      >
        <DialogTitle>{"Filter"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <div className="error_plagarism_report">{ErrorMsg}</div>
          <div className="four_col">
          <div className="col">
          <label className="form-label">Campaign</label>
          <Select
               values ={[dcampaignoption]}
            options={campaignoption}
            defaultValue={[dcampaignoption][0]}
            onChange={(e) => {
              Setcampaign(e[0].value);
              Setdcampaignoption({ label: e[0].label, value: e[0].value })
            }}
          />
        </div>
        <div className="col">
          <label className="form-label">Job Id</label>
          <Select
          values ={[dselectjobid]}
            options={jobidData}
            onChange={(e) => {
              setselectjobid(e[0].value);
              setdselectjobid({ label: e[0].label, value: e[0].value })
            }}
          />
        </div>
        <div className="col">
          <label className="form-label">Search Engine</label>
          <Select
            values ={[dSearchEngine]}
            options={Engineoptions}
            onChange={(e) => {
              SetSearchEngine(e[0].value);
              SetdSearchEngine({ label: e[0].label, value: e[0].value })
            }}
          />
        </div>
        <div className="col">
          <label className="form-label">Country</label>
          <Select
            values ={[dCountryHandle]}
            options={Countryoptions}
            onChange={(e) => {
              SetCountryHandle(e[0]);
              SetdCountryHandle({ label: e[0].label, value: e[0].value })
            }}
          />
        </div>
        <div className="col">
          <label className="form-label">State</label>
          <Select
            values ={[dStateHandle]}
            options={Stateoptions}
            onChange={(e) => {
              SetStateHandle(e[0]);
              SetdStateHandle({ label: e[0].label, value: e[0].value })
            }}
          />
        </div>
        <div className="col">
          <label className="form-label">City</label>
          <Select
             values ={[dCity]}
            options={CityOptions}
            onChange={(e) => {
              console.log("Countrykannavall",Country)
              SetCity(e[0]);
              SetdCity({ label: e[0].label, value: e[0].value })
            }}
          />
        </div>
        
      </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="run_rpt_btn rn_rpt_btn" onClick={finalreportNow} style={{cursor:'pointer'}} >
            Submit
          </button>
          <button   className="run_rpt_btn rn_rpt_btn" style={{cursor:'pointer'}} onClick={closeModal}>close</button>
        </DialogActions>
      </Dialog>
    
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Error Message "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            No Data Found
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
    
      </div>
  );
}
