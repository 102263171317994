import React, { useState, useEffect ,useContext} from "react";
import axios from "axios";
import "./DomainLogindetail.css";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import isURL from 'validator/lib/isURL';
import { useDispatch } from "react-redux";
import { SetDomainHeader } from "../../../Store/DomainData";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../../Context/Context";
function EditDomaindata (){
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);

  const [webauthenticate, Setwebauthenticate] = useState("No")
  const [webtype, Setwebtype] = useState("JS")
  const [showResults, setShowResults] = useState(false)  
  const [websiteName,SetWebsiteName] =useState("")
  const [webLink,SetwebLink] =useState("")
  const [onUsername,SetonUsername] =useState("")
  const [onPassword,SetonPassword] =useState("")
  const [webnameError,SetWebnameError]=useState("")
  const [weblinkError,SetweblinkError]=useState("")
  const [onUserError,SetonUserError]=useState("")
  const [onPasswordError,SetonPasswordError]=useState("")
  const [onPageError,SetonPageError]=useState("")
  const [userdomaindata,SetUserdomaindata]=useState("")
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState([]);
  let querystring = useParams();
  let domain_id = querystring.id;
  const esodata = useSelector((state) => state);
  const ApiToken=esodata.apiauthkey.value
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();
  const navigate = useNavigate();
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const onOptionChange = e => {
    Setwebauthenticate(e.target.value)
    e.target.value==="Yes"?setShowResults(true):setShowResults(false)
    e.target.value==="No"&&SetonUsername("");  SetonPassword("");
  }
  const onwebName = e =>{
    SetWebsiteName(e.target.value)
  }
  const onwebLink = e =>{
    SetwebLink(e.target.value)
  }
  const onWebsiteChange = e => {
    Setwebtype(e.target.value)
  }
  const onWebnameChange = e =>{
    SetonUsername(e.target.value)
  }
  const onpasswordChange = e =>{
    SetonPassword(e.target.value)
  }
  useEffect(() => {
    callUserdataApi();
  }, []);
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    setCardData([])
    try{
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_domain/?user_domain_id=${domain_id}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false)
        console.log(error)
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK"){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
  
      if (datavalue?.data) {
    
      SetWebsiteName(datavalue.data.queryset_values[0].domain__name)
      SetwebLink(datavalue.data.queryset_values[0].domain__url)
      SetonUsername(datavalue.data.queryset_values[0].domain__http_username)
      SetonPassword(datavalue.data.queryset_values[0].domain__http_password)
      SetUserdomaindata(datavalue.data.queryset_values[0].id)
      if(datavalue.data.queryset_values[0].domain__has_http_auth===true){
        Setwebauthenticate("Yes")
        setShowResults(true)
      }
      else{
        Setwebauthenticate("No")
      }
      if(datavalue.data.queryset_values[0].domain__site_type==="html"){
        Setwebtype("HTML")
      }
      else{
        Setwebtype("JS")
      }
      }
    }catch(err){
      console.log(err)
    }
    setLoading(false);
  };

  const finalreportCancel =async () =>{
    window.location.href=`${process.env.REACT_APP_DOMAIN}domain-list`

  }
  const finalreportNow = async () =>{
    SetonPageError("")
    SetWebnameError("")
    SetweblinkError("")
    SetonUserError("")
    SetonPasswordError("")
    setLoading(true);
    var data_val=1;
    if(websiteName===""){
      SetWebnameError("Enter Website Name")
      data_val=2;
    }
    if(webLink===""){
      SetweblinkError("Enter Link Address")
      data_val=2;
    }
    if(webLink!==""){
    if (!isURL(webLink)) {
      SetweblinkError("Enter Valid Link Address")
      data_val=2;
   } 
  }
    if(webauthenticate==="Yes"){
      if(onUsername===""){
        SetonUserError("Enter User Name")
      }
      if(onPassword===""){
        SetonPasswordError("Enter Password")
      }
    }
    if(data_val===2){
    setLoading(false);

      return false;
    }
    else{
      try{
    setLoading(true);
    if(webauthenticate=="Yes")
    var webauthenticates=true;
    else
    var webauthenticates=false;

        const response=await axios.post(
       `${process.env.REACT_APP_API_URL}domain/edit_domain/`,
         {
          domain_name:   websiteName,
          domain_url:  webLink,
          domain_type:   webtype,
          domain_has_http:   webauthenticates,
          domain_http_username:   onUsername,
          domain_http_password:   onPassword,
          user_domain_id:userdomaindata,
         },
          {
                headers: {
                  Authorization: `${ApiToken}`,
                },
              }       
     ).catch((error) => {
      setLoading(false)
      console.log(error)
      if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if(error?.code =="ERR_NETWORK"){
        navigate('/errorpages')    
      }
      // console.log("kannan",error)
    });
     if(response?.data?.status_code==200)
     {
      dispatch(SetDomainHeader({header_selected:response.data.user_domain_id}))
       window.location.href=`${process.env.REACT_APP_DOMAIN}domain-list`
     }
   }catch(err){
    setLoading(false);
    SetonPageError(err.response.data.error)
   }
    }

  }
    return (
      <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <div className="userdatas">
          {/* <h1>Domain Data</h1> */}
          <div className="userdatas_inner">
            <p>Edit Website Details....</p>
            <span className="error-value">{onPageError}</span>

            <form className="frm_container">
              <table className="form-table" align="center" style={{ padding: "20px", width: "90%" }}>
                <tr>
                  <th>
                    <label>Website Name:</label>
                  </th>
                  <td>
                    <input
                      title="This name is used as an alias to manage your domain."
                      type="text"
                      name="websitename"
                      onChange={onwebName}
                      value={websiteName}
                    />
                    <span className="error-value">{webnameError}</span>
                  </td>
                </tr>

                <tr>
                  <th>
                    <label>Website Link:</label>
                  </th>
                  <td>
                    <input
                      type="text"
                      title="URL of your website (ex: www.example.com)"
                      name="websitelink"
                      onChange={onwebLink}
                      value={webLink}
                    />
                    <span className="error-value">{weblinkError}</span>
                  </td>
                </tr>

                <tr>
                  <th>
                    <label>Website Type:</label>
                  </th>
                  <td>
                    <div className="websitetype_inner">
                      <input
                        type="radio"
                        id="html"
                        name="fav_language"
                        value="HTML"
                        checked={webtype === "HTML"}
                        onChange={onWebsiteChange}
                      />
                      <label htmlFor="html">HTML</label>
                    </div>
                    <div className="websitetype_inner">
                      <input
                        type="radio"
                        id="css"
                        name="fav_language"
                        value="JS"
                        checked={webtype === "JS"}
                        onChange={onWebsiteChange}
                      />
                      <label htmlFor="css">JS</label>
                    </div>
                  </td>
                </tr>

                <tr>
                  <th>
                    <label>Does your website need authentication to access?</label>
                  </th>
                  <td>
                    <div className="websiteauthen_inner " style={{ marginLeft: "15px" }}>
                      <input
                        type="radio"
                        id="yes"
                        name="webauthenticate"
                        value="Yes"
                        checked={webauthenticate === "Yes"}
                        onChange={onOptionChange}
                      />
                      <label htmlFor="yes">Yes</label>
                    </div>
                    <div className="websiteauthen_inner">
                      <input
                        type="radio"
                        id="no"
                        name="webauthenticate"
                        value="No"
                        checked={webauthenticate === "No"}
                        onChange={onOptionChange}
                      />
                      <label htmlFor="no">No</label>
                    </div>
                  </td>
                </tr>

                {showResults && (
                  <>
                    <tr>
                      <th>
                        <label htmlFor="textField">Username:</label>
                      </th>
                      <td>
                        <input
                          type="text"
                          id="textField"
                          title="User Name"
                          onChange={onWebnameChange}
                          value={onUsername}
                        />
                        <span className="error-value">{onUserError}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label htmlFor="textField">Password:</label>
                      </th>
                      <td>
                        <input
                          type="text"
                          id="textField"
                          title="Password"
                          onChange={onpasswordChange}
                          value={onPassword}
                        />
                        <span className="error-value">{onPasswordError}</span>
                      </td>
                    </tr>
                  </>
                )}

                <tr className="form_next">
                  <th>
                    <button className="nextbutton" type="button" onClick={finalreportCancel}>
                      Cancel
                    </button>
                  </th>
                  <td>
                    <button className="nextbutton" type="button" onClick={finalreportNow}>
                      Submit
                    </button>
                  </td>
                </tr>
              </table>
            </form>
          </div>

      </div> 
      <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>  
      </div>  

    );
}

export default EditDomaindata;


