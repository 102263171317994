import React, { useState, useEffect ,useContext} from "react";
import { styled, useTheme } from "@mui/material/styles";
import "./Regressionstyle.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "react-dropdown-select";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import Loader from "../CommonLoader";
import { Tooltip } from "@mui/material";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Keywordbar from "./../Rightsidebar/Keywordbar";
import { useNavigate } from "react-router-dom";
import { Commonfun } from './../Commonfun';
import { AppContext } from "../../../Context/Context";
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Siteanalysis() {
  const isMobile = useMediaQuery('(max-width:620px)');

  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectval, setSelectval] = useState([]);
  const [selectsecondval, setSelectsecondval] = useState([]);
  const [menubutval,setMenubutval]=useState("1")
  const [buttnval,setButtnval]=useState("1")
  const [pagename,setPagename]=useState("Regression Test")
  const [sitecardData, setSitecardData] = useState([]);
  const [internalcardData, setInternalcardData] = useState([]);
  const [seoindexData, setSeoindexData] = useState([]);
  const [seooccurData, setSeooccurData] = useState([]);
  const [latestoccurData, setLatestoccurData] = useState([]);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [value, setValue] = useState("1");
  const [chartvalue, setChartvalue] = useState("4");
  const [domainval,setDomainval]=useState([])
  const [reportdata, setReportdata] = useState([]);
  const [reporttwodata, setReporttwodata] = useState([]);
  const [latestdataarr, setLatestdataarr] = useState([]);
  const [earlierdataarr, setEarlierdataarr] = useState([]);
  const [arrheaderdata, setArrheaderdata] = useState([]);
  const [serveritydata, setServeritydata] = useState([]);
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [DoaminSectionUrl,SetDoaminSectionUrl]=useState([])
  const [Domainsectiondata,SetDomainsectiondata]=useState([])
  const esodata = useSelector((state) => state);
  const ApiToken=esodata.apiauthkey.value
  const [faqData,setfaqData]=useState("2")
  const [initialDateval, SetInitialDateval] = useState([]); 
  const [toggle, setToggle] = useState(false);
  const [autoheightval,SetAutoheightval]=useState(true)
  const [siteautoheightval,Setsiteautoheightval]=useState(true)
  const [interautoheightval,Setinterautoheightval]=useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();
  const [domainOne,setDomainOne]=useState("");
  const [domainTwo,setDomainTwo]=useState("");


  
  const navigate = useNavigate();
  const apihandleClose = () => {
    setapiOpen(false);
  };

  const handleToggle = () => {
    if(faqData!=1){
      setfaqData(1)
    }
    else{
      setfaqData(2)
    }
        setToggle((pre) => !pre);
  };
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') || 
    (event.target.classList[0] === 'nav_key_txt') || 
    (event.target.classList[0] === 'cnt_hdr_top_wrap') ||(event.target.classList[0] === 'react-dropdown-select') 
    ||(event.target.classList[0] === 'react-dropdown-select-content') 
    ||(event.target.classList[0] === 'react-dropdown-select-item') 
    || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap')   || (event.target.classList[0] === 'App')  
    || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
     || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row') 
     || (event.target.classList[0] === 'MuiDataGrid-cell')  || (event.target.classList[0] === 'MuiDataGrid-cellContent')
     || (event.target.classList[0] === 'header_inner_btm_wrap')||(event.target.classList[0] === 'over_all_container') 
     ||(event.target.classList[0] === 'input_domain_field') ||(event.target.classList[0] === 'react-dropdown-select-input')
     ||(event.target.classList[0] === 'run_rpt_btn') ||(event.target.classList[0] === 'urlcheck') 
     ||(event.target.classList[0] === 'link_cnt_hdr_blw_wrap') ||(event.target.classList[0] === 'MuiDataGrid-columnHeaderTitle') 
     ||(event.target.classList[0] === 'cols') ||(event.target.classList[0] === 'card_num_class') 
     ||(event.target.classList[0] === 'content_col') || (event.target.classList[0] ==='link_rn_rpt_btn') 
     || (event.target.classList[0] ==='css-b5h07o') || (event.target.classList[0] ==='site_regress_chart_info') 
      || (event.target.classList[0] ==='linechartdesign')||(event.target.classList[0] ==='MuiButtonBase-root')
      ||(event.target.classList[0] ==='MuiDataGrid-toolbarContainer')||(event.target.classList[0] ==="_btn_icon_calender")

    ) {
      setfaqData(2)
      setToggle(false)   
    }
});
const faqdatafun = async(menubutval="") => {
  var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=24`
  try {
    const doaminsectiondatavalue = await axios.get(
      datavar,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404 ){
        navigate('/errorpages')    
      }
      // console.log("kannan",error)
    });
    if (doaminsectiondatavalue?.data) {
     
      SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
    }
  } catch (err) {}
}
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handlechartChange = (event, newValue) => {
    setChartvalue(newValue);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
  const barChartData = {
    labels:seoindexData ,
    datasets: [
      {
        label: 'Earlier Occurence',
        data: seooccurData,
        backgroundColor: ["#103658"],
      },
      {
        label: 'Latest Occurence',
        data: latestoccurData,
        backgroundColor: [ "#fe874e"],
      },
     
    ],
  };
  const barChartData1 = {
    labels: arrheaderdata,
    datasets: [
      {
        label: 'Earlier Occurence',
        data: earlierdataarr,
        backgroundColor: ["#103658"],
      },
      {
        label: 'Latest Occurence',
        data: latestdataarr ,
        backgroundColor: [ "#fe874e"],
      },
     
    ],
  };


  const SeoMetricsArray=[
    
    {
    key:'no_index_robot',
    value:'The web pages must be accessible to Search engines for better SEO results. This metric will check if the web page is accessible to search engines'
  },
  {
    key:'missing_canonical',
    value:'Canonical URLs inform search engines that a specific URL represents the source of the content on a web page. This metric will check for a canonical URL or tag on the web page.or tag on the web page'
  },
  {
    key:'missing_titles',
    value:'The title tag specifies the web page title. This metric will show the total number of webpages that have a missing title.'
  },
  {
    key:'duplicate_h1_tags',
    value:'H1 tags are used to define important HTML headings. This metric will show the total number of webpages that have the same H1 tags.'
  },
  {
    key:'missing_h1_tags',
    value:'H1 tags are used to define important HTML headings. This metric will show the total number of webpages that have missing H1 tags.'
  },
  {
    key:'hreflang',
    value:"Hreflang is an HTML attribute used to specify a web page's language and geographic target. This metric displays the total count of webpages using the hreflang attribute."
  },
  {
    key:'pagination_prev_or_next',
    value:"This metrics displays the count of Page URLs which have next and previous URLs"
  },
  {
    key:'duplicate_titles',
    value:"The title tag specifies the web page title. This metric will show the total number of web pages with the same title."
  },
  {
    key:'duplicate_descriptions',
    value:"The description tag specifies the description of the web page. This metric populates the total number of webpages that have the same description."
  },
  {
    key:'missing_descriptions',
    value:"The description tag specifies the description of the web page. This metric populates the total number of web pages that have a missing description."
  },
  {
    key:'missing_alt_tags',
    value:"The ALT tag describes the presence or function of an image on a web page. This metric will show the total count of images on a web page that have missing ALT tags."
  },
  {
    key:'h1_tags_available',
    value:"H1 tags are used to define important HTML headings. This metric will show the total number of webpages that have an H1 tag. will show the total number of webpages that have the same H1 tags. that have an H1 tag"
  },
  {
    key:'images_available',
    value:"Images Available	This metric provides the total number of images available in the domain."
  },
  {
    key:'server_error_5xx_link',
    value:"The 5xx status code means that the server failed to complete a request. This metric displays the count of web pages that return a 5xx status code."
  },
  {
    key:'broken_links_4xx_link',
    value:"The 4xx status code means that the request for a web page or other resource contains bad syntax. This metric displays the count of web pages that return a 4xx status code."
  }


];
const LoadAddFormFilterOptions = async () => {
  SetSectionOptions([]);
  try {
    const doaminsectiondatavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404 ){
        navigate('/errorpages')    
      }
      // console.log("kannan",error)
    });
    if (doaminsectiondatavalue?.data) {
      if (doaminsectiondatavalue?.data?.domain_sections) {
      
        SetDoaminSectionUrl(doaminsectiondatavalue?.data?.domain_sections)
        var Sections = [];
        doaminsectiondatavalue.data.domain_sections.forEach((element) => {
          Sections.push({ label: element.name, value: element.id });
        });
        SetSectionOptions(Sections);
      }
    }
  } catch (err) {}
} 
function findObjectByKeyValue(array, key, value) {
  return array.find(obj => obj[key] === value);
}

  const column12 = [
    {
      field: "id",
      headerName: "#",
      description:'Index of the row',
      width: 70,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      
    },
    {
      field: "seo_metrics",
      headerName: "Seo Metrics",
      description:'On-page SEO elements are present on the website.',
      width: 300,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
  
      renderCell: (cardData) => {

        var ToolTipTitle='';

        const result = findObjectByKeyValue(SeoMetricsArray, 'key',cardData.row.seo_metrics);

        if(result!==undefined)
        {
          ToolTipTitle=result.value
        }

     

        if(ToolTipTitle!='')
        {
          return (
            <Tooltip title={ToolTipTitle}>
            <p>{cardData.row.seo_metrics}</p>
            </Tooltip>
          );
        }
        
        else{

          return (
            <p>{cardData.row.seo_metrics}</p>
          );

        }
       
      },
    },
    {
      field: "priority",
      headerName: "Priority",
      description:'SEO and Site parameters can be prioritized and categorized according to your organization’s marketing strategies in the site audit settings',
      width: 300,
      headerAlign: "center",
      align: "center",
      
          renderCell: (cardData) => {
        return (
          <p className="button_score_org">{`${cardData?.row?.priority}`}</p>
        );
      },
    
    },
    {
      field: "latest_occurences",
      headerName: "Latest Occurence",
      width: 250,
     
 
      headerAlign: "center",
      align: "center",
    
    },
    {
      field: "earlier_occurences",
      headerName: "Earlier Occurences",
      width: 250,
      headerAlign: "center",
      align: "center",
    
    },
  ];
  const column1 = [
    {
      field: "id",
      headerName: "#",
      width: 70,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description:'Index of the row',
      
    },
    {
      field: "site_metrics",
      headerName: "Site Metrics",
      description:'Website metrics are present on the website.',
      width: 300,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
  
      renderCell: (cardData) => {

        var ToolTipTitle='';

        const result = findObjectByKeyValue(SeoMetricsArray, 'key',cardData.row.site_metrics);

        if(result!==undefined)
        {
          ToolTipTitle=result.value
        }

     

        if(ToolTipTitle!='')
        {
          return (
            <Tooltip title={ToolTipTitle}>
            <p>{cardData.row.site_metrics}</p>
            </Tooltip>
          );
        }
        
        else{

          return (
            <p>{cardData.row.site_metrics}</p>
          );

        }
       
      },
    },
    {
      field: "priority",
      headerName: "Priority",
      description:'SEO and Site parameters can be prioritized and categorized according to your organization’s marketing strategies in the site audit settings',
      width: 300,
      headerAlign: "center",
      align: "center",
 
      renderCell: (cardData) => {
        return (
          <p className="button_score_org">{`${cardData?.row?.priority}`}</p>
        );
      },
    },
    {
      field: "latest_occurences",
      headerName: "Latest Occurrences",
      width: 250,
      headerAlign: "center",
      align: "center",

    },
      {
      field: "earlier_occurences",
      headerName: "Earlier Occurences",
      width: 250,
      headerAlign: "center",
      align: "center",
   
    },
  ];
  const column2 = [
    {
      field: "id",
      headerName: "#",
      width: 70,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description:'Index of the row',
      
    },
    {
      field: "internal_links",
      headerName: "Internal Metrics",
      description:'It displays server error links (5xx) and broken links (4xx) on the website.',
      width: 300,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",

      renderCell: (cardData) => {

        var ToolTipTitle='';

        const result = findObjectByKeyValue(SeoMetricsArray, 'key',cardData.row.internal_links);

        if(result!==undefined)
        {
          ToolTipTitle=result.value
        }

     

        if(ToolTipTitle!='')
        {
          return (
            <Tooltip title={ToolTipTitle}>
            <p>{cardData.row.internal_links}</p>
            </Tooltip>
          );
        }
        
        else{

          return (
            <p>{cardData.row.internal_links}</p>
          );

        }
       
      },
      
    },
    {
      field: "priority",
      headerName: "Priority",
      description:'SEO and Site parameters can be prioritized and categorized according to your organization’s marketing strategies in the site audit settings',
      width: 300,
      headerAlign: "center",
      align: "center",
      
      renderCell: (cardData) => {
        return (
          <p className="button_score_org">{`${cardData?.row?.priority}`}</p>
        );
      },
    },
      {
      field: "latest_occurences",
      headerName: "Latest Occurrences",
      width: 250,
      headerAlign: "center",
      align: "center",
 
    },
      {
      field: "earlier_occurences",
      headerName: "Earlier Occurences",
      width: 250,
      headerAlign: "center",
      align: "center",
  
    },
  ];
  const rows = cardData ?? [];
   const rows1 = sitecardData ?? [];
  const rows2 = internalcardData ?? [];
 const reportNow = async () => {
    setLoading(true);
    var errs = 1;
    let errors = {};
    // if(domainOne == "")
    // {
    //   errors.domainerror = "Please Select Domain 1.";
    //   errs = 2;
    // }
    // if(domainTwo=="")
    //   {
    //     errors.domaintwoerror = "Please Select Domain 2.";
    //     errs = 2;
    //   }
    if (selectval.length === 0  ) {
      errors.dateerror = "Please Select Report.";
      errs = 2;
    } 
    if(selectsecondval.length === 0 ){
      errors.dateerror1 = "Please Select Report.";
      errs = 2;
    }
    else {
      errors.dateerror = "";
      errs = 1;
     
    }
    if (errs === 2) {
      setLoading(false);
      return setValidation(errors);
    }
    setValidation(errors);
    callUserdataApi(selectval[0].id,selectsecondval[0].id)
    closeModal();
    setLoading(false);
  };
  const pdfdown = async () =>{
    setLoading(true);
    try{
    const response = await fetch(`${process.env.REACT_APP_API_URL}technical/regression_report/?user_domain_id=${esodata.domaindata.value.header_selected}&is_format=pdf`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();

                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                var filedata=await Commonfun("RegressionTest","pdf", ApiToken,esodata.domaindata.value.header_selected);
                // return false;
                link.download = filedata;
                link.click();

                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              if(response?.status==599 || response?.status==500 || response?.status==400  || response?.status==404 ){
                response.json().then(data => {
                  setapiOpen(true);
                  setapierrorMsg(data?.error)
                  console.log('Resolved object:', data.error);
                })
              }
              else if(response?.code =="ERR_NETWORK"){
                navigate('/errorpages')    
              }
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });

            console.error('Error during PDF download:', error);
        }
        setLoading(false);
  }
  const csvdown = async () =>{
    setLoading(true);
    try{
    const response = await fetch(`${process.env.REACT_APP_API_URL}technical/regression_report/?user_domain_id=${esodata.domaindata.value.header_selected}&is_format=csv`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/csv',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();

                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                var filedata=await Commonfun("RegressionTest","csv", ApiToken,esodata.domaindata.value.header_selected);
                // return false;
                link.download = filedata;
                link.click();

                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              if(response?.status==599 || response?.status==500 || response?.status==400  || response?.status==404 ){
                response.json().then(data => {
                  setapiOpen(true);
                  setapierrorMsg(data?.error)
                  console.log('Resolved object:', data.error);
                })
              }
              else if(response?.code =="ERR_NETWORK"){
                navigate('/errorpages')    
              }
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });

            console.error('Error during PDF download:', error);
        }
        setLoading(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  function dataChange(e){
    let dataval_id=e[0].id
    setDomainOne(e[0].id)
    let fun_name=1
    calljobiddata(dataval_id,fun_name)
  }
  function datavalChange(e){
    let dataval_id=e[0].id;
    setDomainTwo(e[0].id)
    let fun_name=2
    calljobiddata(dataval_id,fun_name)
  }
  const SetSectionNameHandle=(passvalue)=>{
    SetDomainsectiondata(passvalue[0].value)
    callUserdataApi("","",passvalue[0].value);
  }
  const calljobiddata = async (dataval_id ="",fun_name ="")=>{
    setLoading(true);
    const datedatavalue = await axios
      .get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=site_audit&user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      )
      .catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404 ){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      setLoading(false)
  
      setReportdata(datedatavalue?.data?.jobs)
      setReporttwodata(datedatavalue?.data?.jobs)
  }
  useEffect(() => {
    callUserdataApi();
    calljobiddata();
    LoadAddFormFilterOptions();
    faqdatafun();
  }, []);
  const callUserdataApi = async (jobid = "",secondjobid ="",domainsecdata="") => {
    if(domainsecdata=="")
      domainsecdata=Domainsectiondata;
    
    setLoading(true);
    const datavalue = await axios
      .get(
        `${process.env.REACT_APP_API_URL}technical/regression_report/?user_domain_id=${esodata.domaindata.value.header_selected}&latest_job_id=${jobid}&earlier_job_id=${secondjobid}&domain_section_id=${domainsecdata}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      )
      .catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404 ){
          navigate('/errorpages')    
        }
        setLoading(false);
        // console.log("kannan",error)
      });
      const domain_value=await axios
      .get(
        `${process.env.REACT_APP_API_URL}domain/get_user_domains/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      )
      .catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404 ){
          navigate('/errorpages')    
        }
        setLoading(false);
        // console.log("kannan",error)
      });
      if(datavalue?.data){
        const dataval = datavalue?.data?.table?.seo_metrics_list;
        const domainval=domain_value?.data;
        const dataval1 = datavalue?.data?.table?.site_metrics_list;
        const dataval2 = datavalue?.data?.table?.internal_links;
        const chartval = datavalue?.data?.graph?.issue_graph;
        const seoindex = chartval?.map(function (val, index) {
          return val.y;
        });
        const seooccurence = chartval?.map(function (val, index) {
          return val.earlier_count;
        });
         const latestoccurence = chartval?.map(function (val, index) {
          return val.latest_count;
        });
        setSeoindexData(seoindex);
        setSeooccurData(seooccurence);
        setLatestoccurData(latestoccurence);
        setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));

      
   


    if(dataval && dataval.length>0)
    {
      SetAutoheightval(false)
      const newData = dataval.map((obj, index) => {
        return { ...obj, id: index+1 };
      });
  
      setCardData(newData);
    }
    else{
      setCardData("");
      SetAutoheightval(true)
    }


    if(dataval1 && dataval1.length>0)
    {
      Setsiteautoheightval(false)
      const newData1 = dataval1.map((obj, index) => {
        return { ...obj, id: index+1 };
      });
  
      setSitecardData(newData1);
    }
    else{
      setSitecardData("")
      Setsiteautoheightval(true)
    }

    if(dataval2 && dataval2.length>0)
    {
      Setinterautoheightval(false)
      const newData2 = dataval2.map((obj, index) => {
        return { ...obj, id: index+1 };
      });
  
      setInternalcardData(newData2);
    }
    else{
      Setinterautoheightval(true)
      setInternalcardData("")
    }
   


    setDomainval(domainval)
   
    const latest_dataarrvals = [];
    const earlier_dataarrvals = [];
    const severity_dataarrvals = [];

    latest_dataarrvals[0]=datavalue?.data?.graph?.latest_high_count;
    earlier_dataarrvals[0]=datavalue?.data?.graph?.earlier_high_count;
    latest_dataarrvals[1]=datavalue?.data?.graph?.latest_medium_count;
    earlier_dataarrvals[1]=datavalue?.data?.graph?.earlier_medium_count;
    latest_dataarrvals[2]=datavalue?.data?.graph?.latest_low_count;
    earlier_dataarrvals[2]=datavalue?.data?.graph?.earlier_low_count;

    severity_dataarrvals[0]=datavalue?.data?.graph?.latest_high_serverity_graph;
    severity_dataarrvals[1]=datavalue?.data?.graph?.earlier_high_severity_graph;
    severity_dataarrvals[2]=datavalue?.data?.graph?.latest_medium_serverity_graph;
    severity_dataarrvals[3]=datavalue?.data?.graph?.earlier_medium_serverity_graph;
    severity_dataarrvals[4]=datavalue?.data?.graph?.latest_low_severity_graph;
    severity_dataarrvals[5]=datavalue?.data?.graph?.earlier_low_severity_graph;
    severity_dataarrvals[6]=datavalue?.data?.latest_job_details?.latest_job_domain;
    severity_dataarrvals[7]=datavalue?.data?.earlier_job_details?.earlier_job_domain;
    severity_dataarrvals[8]=(moment(datavalue?.data?.latest_job_details?.latest_job_date).format("MMM DD,YYYY "));
    severity_dataarrvals[9]=(moment(datavalue?.data?.earlier_job_details?.earlier_job_date).format("MMM DD,YYYY "));
    setServeritydata(severity_dataarrvals)
    const datahearvals=['High impact Issue Occuerrences','Medium Impact Issue Occurence','Low Impact Issue Occurence']
    setArrheaderdata(datahearvals)
    setLatestdataarr(latest_dataarrvals)
    setEarlierdataarr(earlier_dataarrvals)     
    setLoading(false);
  };
}
  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "#fe874e";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];
  return (
<>
{loading === true ? <Loader /> : null}

<ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
   
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Regression Test</title>
            </Helmet>
          {/* {
      loading===true ?   <Loader/> : null
    } */}
    <div className="cnt_audit_outer_wrap">
      <div className="cnt_hdr_top_wrap">
        <div className="cnt_hdr_top_lft_wrap">
         <h2> {pagename}</h2>
        </div>
        <div className="cnt_hdr_top_rgt_wrap">
        <div className={`App ${faqData=="1"?'faq_move_class':""}`} >
            <button onClick={handleToggle} className="faq_class"  title="Frequently Asked Question">
            <QuestionMarkIcon />
              
            </button>
           {toggle && <Keywordbar close={() => setToggle(false)} initialDateval={initialDateval} />} 
          </div>
        <img src={pdficon} alt="pdf icon" onClick={pdfdown}/>
          <img src={csvicon} alt="csv icon" onClick={csvdown} />
        </div>
      </div>
      <div className="site_blw_wrap regressiontest">
        <div className="cnt_hdr_blw_wrap">
          {/* <button className="run_rpt_btn rn_rpt_btn" onClick={reportNow}>
            Run Report Now
          </button> */}
          <button className="run_rpt_btn ch_date_btns" onClick={openModal} style={{display:"flex",alignItems:"center"}}>
          Choose Report
            <span className="_btn_icon_calender">
              <img src={calendarpng} alt="calender logo" />
            </span>
          </button>
          {SectionOptions?.length > 0 &&
      <div className="listdropdown">
              <div>
                    <span className="listdropdown_titl">Domain Section</span>
                        
                       <Select
                    className="drop-organicsearch"
                    name="name"
                    isClearable
                    onChange={SetSectionNameHandle}
                    options={SectionOptions}
                    isSearchable="true"
                     placeholder="Home Page"
                     />
                    
                  </div>
                </div>
                    }
        </div>
      </div>
      </div>
    
      <div className="tabs_graph_outer_wrap">
      <TabContext value={chartvalue}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handlechartChange} aria-label="lab API tabs example" >

            <Tooltip title="Clicking on this option displays an issue-based chart comparing the reports selected">
              <Tab className="tabbutton"      id={chartvalue === "4" ? "activetabj" : ""} label="By Issue" value="4" />
              </Tooltip>
              <Tooltip title="By clicking on this option, the current severity-based bar chart comparison will be displayed.">

              <Tab className="tabbutton"      id={chartvalue === "5" ? "activetabj" : ""} label="By Severity" value="5" />

              </Tooltip>
            </TabList>
          </Box>
          <TabPanel value="4">
      <div className="site_regress_chart_info">
        {/* <p>Site Audit Summary by Issues</p> */}
        <div className="site_Chart_info_inner reg-test">
          <Bar className="linechartdesign"
            type="bar"
            width={130}
            height={isMobile ? 150 : 50}
            data={barChartData}
            options={{
              responsive: true,
              plugins: {
                
                // 'legend' now within object 'plugins {}'
                legend: {
                  position: 'top',
                  display: true,
                  labels: {
                    color: '#0b2033',
                    font: {
                      size: 15,
                      family:'Open Sans',
                    }
                  }
                  
                },
                title: {
                  display: true,
                  text: 'Regression Site Audit Summary by Issue',
                  color: '#0b2033',
                    font: {
                      size: 18,      
                      family:'Open Sans',             
                    }
                  
                },
              },
              scales: {
                y: {
                  // not 'yAxes: [{' anymore (not an array anymore)
                  ticks: {
                    suggestedMin: 0,
                    stacked: true,
                    color: "#0b2033", // not 'fontColor:' anymore
                    // fontSize: 18,
                    font: {
                      size: 14, // 'size' now within object 'font {}'
                      family:'Open Sans',
                    },
                    beginAtZero: true,
                  },
                },
                x: {
                  // not 'xAxes: [{' anymore (not an array anymore)
                  ticks: {
                    color: "#0b2033", // not 'fontColor:' anymore
                    //fontSize: 14,
                    font: {
                      size: 12, // 'size' now within object 'font {}'
                      family:'Open Sans',
                    },
                    beginAtZero: true,
                  },
                },
              },
            }}
          />
        </div>
      
      </div>
      </TabPanel>
      <TabPanel value="5">
      <div className="site_regress_chart_info">
        {/* <p>Site Audit Summary by Issues</p> */}
        <div className="site_Chart_info_inner reg-test">
          <Bar className="linechartdesign"
            type="bar"
            width={130}
            height={isMobile ? 150 : 50}
            data={barChartData1}
            options={{
              responsive: true,
              plugins: {
                
                // 'legend' now within object 'plugins {}'
                legend: {
                  position: 'top',
                  display: true,
                  labels: {
                    color: '#0b2033',
                    font: {
                      size: 15,
                      family:'Open Sans',
                    }
                  }
                  
                },
                title: {
                  display: true,
                  text: 'Regression Site Audit Summary by Serverity',
                  color: '#0b2033',
                    font: {
                      size: 18,   
                      family:'Open Sans',                
                    }
                  
                },
              },
              scales: {
                y: {
                  // not 'yAxes: [{' anymore (not an array anymore)
                  ticks: {
                    suggestedMin: 0,
                    stacked: true,
                    color: "#0b2033", // not 'fontColor:' anymore
                    // fontSize: 18,
                    font: {
                      size: 14, // 'size' now within object 'font {}'
                      family:'Open Sans',
                    },
                    beginAtZero: true,
                  },
                },
                x: {
                  // not 'xAxes: [{' anymore (not an array anymore)
                  ticks: {
                    color: "#0b2033", // not 'fontColor:' anymore
                    //fontSize: 14,
                    font: {
                      size: 12, // 'size' now within object 'font {}'
                      family:'Open Sans',
                    },
                    beginAtZero: true,
                  },
                },
              },
            }}
          />
        </div>
        <table>
          <tr className="table_regression">
            <td className="table_td_regression">{serveritydata[6]}( {serveritydata[8]}) - {serveritydata[0]}%</td>
            <td className="table_td_regression">{serveritydata[6]}( {serveritydata[8]}) - {serveritydata[2]}%</td>
            <td className="table_td_regression">{serveritydata[6]}( {serveritydata[8]})- {serveritydata[4]}%</td>
          </tr>
          <tr className="table_regression">
            <td className="table_td_regression">{serveritydata[7]}( {serveritydata[9]}) - {serveritydata[1]}%</td>
            <td className="table_td_regression">{serveritydata[7]}( {serveritydata[9]}) - {serveritydata[3]}%</td>
            <td className="table_td_regression">{serveritydata[7]}( {serveritydata[9]}) - {serveritydata[5]}%</td>
          </tr>
        </table>
      </div>
      </TabPanel>
      </TabContext>
      </div>


      <div className="tabs_outer_wrap tabs_outer_wrap_tab_btm_rem">
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">


            <Tooltip     title="On-page SEO elements are present on the website.">
              <Tab label="SEO Metrics" value="1"      id={value === "1" ? "activetabj" : ""} />
              </Tooltip>

              <Tooltip     title="Website metrics are present on the website.">

              <Tab label="Site Metrics" value="2"      id={value === "2" ? "activetabj" : ""} />
              </Tooltip>
              <Tooltip     title="It displays server error links (5xx) and broken links (4xx) on the website.">

              <Tab label="Internal Links" value="3"      id={value === "3" ? "activetabj" : ""} />
              </Tooltip>
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className="data_table" style={{ width: "100%" }}>
              <div style={{ height: 550, width: "100%" }}>
                <DataGrid
                  sx={{
                    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                      py: "8px",
                    },
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                      py: "15px",
                    },
                    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                      py: "22px",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                    },
                    "&, [class^=MuiDataGrid]": { border: "none" },
                    ".MuiDataGrid-virtualScroller": {
                      height: "auto !important",
                      overflowY: "auto",
                    },
                    "& .MuiDataGrid-cell:first-child": {
                      position: "sticky",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      zIndex: 999,
                    },
                    "& .MuiDataGrid-columnHeader:first-child": {
                      position: "sticky",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      border: "none",
                      zIndex: 999,
                    },
                  }}
                  autoHeight={autoheightval?true:false} 
                  getRowHeight={() => 'auto'}
                  getRowId={(row) => row.seo_metrics}
                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar
                  }}
                  slotProps={{
                    toolbar: {
                  
                      showQuickFilter: true,
                    
                    },
                  }}
                  rows={rows}
                  columns={column12}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10, 30, 50, 75, 100]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2">
            <div className="data_table" style={{ width: "100%" }}>
              <div style={{ height: 550, width: "100%" }}>
                <DataGrid
                  sx={{
                    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                      py: "8px",
                    },
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                      py: "15px",
                    },
                    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                      py: "22px",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                    },
                    "&, [class^=MuiDataGrid]": { border: "none" },
                    ".MuiDataGrid-virtualScroller": {
                      height: "auto !important",
                      overflowY: "auto",
                    },
                    "& .MuiDataGrid-cell:first-child": {
                      position: "sticky",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      zIndex: 999,
                    },
                    "& .MuiDataGrid-columnHeader:first-child": {
                      position: "sticky",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      border: "none",
                      zIndex: 999,
                    },
                  }}
                  autoHeight={siteautoheightval?true:false}
                  getRowHeight={() => 'auto'}
                  getRowId={(row) => row.site_metrics}
                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar
                  }}
                  slotProps={{
                    toolbar: {
                  
                      showQuickFilter: true,
                    
                    },
                  }}
                  rows={rows1}
                  columns={column1}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10, 30, 50, 75, 100]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value="3">
            <div className="data_table" style={{ width: "100%" }}>
              <div style={{ height: 550, width: "100%" }}>
                <DataGrid
                  sx={{
                    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                      py: "8px",
                    },
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                      py: "15px",
                    },
                    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                      py: "22px",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                    },
                    "&, [class^=MuiDataGrid]": { border: "none" },
                    ".MuiDataGrid-virtualScroller": {
                      height: "auto !important",
                      overflowY: "auto",
                    },
                    "& .MuiDataGrid-cell:first-child": {
                      position: "sticky",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      zIndex: 999,
                    },
                    "& .MuiDataGrid-columnHeader:first-child": {
                      position: "sticky",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      border: "none",
                      zIndex: 999,
                    },
                  }}
                  autoHeight={interautoheightval?true:false}
                  getRowHeight={() => 'auto'}
                  getRowId={(row) => row.internal_links}
                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar
                  }}
                  slotProps={{
                    toolbar: {
                  
                      showQuickFilter: true,
                    
                    },
                  }}
                  rows={rows2}
                  columns={column2}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10, 30, 50, 75, 100]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                />
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </div>

      <Dialog
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter"
      >
        <DialogTitle>{"Regression Form"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">
            
            <div>

            {/* <div className="">
            <div>
              <Tooltip     title="Select the domain for which you have to compare the results">
                  <span>Domain 1</span>
                  </Tooltip>
                  <Select
                    options={domainval}
                    labelField="domain__name"
                    valueField="id"
                    onChange={dataChange}
                  />
                </div>
                <div className="col">
                <span className="text-error">{validation.domainerror}</span>
              </div>
               
              </div> */}
            

                <div className="">
                <div>
                <Tooltip     title="Select the report date for which the results have to be compared">
                  <span>Select Report 1</span>
                  </Tooltip>
                  <Select
                    options={reportdata}
                    labelField="completed_at"
                    valueField="id"
                    onChange={(values) => setSelectval(values)}
                  />
                </div>
           
               
              </div>
              <div className="col">
                <span className="text-error">{validation.dateerror}</span>
              </div>
              {/* <div className="">
              <div>
              <Tooltip     title="Select the domain for which you have to compare the results">
                  <span>Domain 2</span>
                  </Tooltip>
                  <Select
                    options={domainval}
                    labelField="domain__name"
                    valueField="id"
                    onChange={datavalChange}
                  />
                </div>
                <div className="col">
                <span className="text-error">{validation.domaintwoerror}</span>
              </div>
               
              </div> */}

              <div className="">
              <div>
              <Tooltip     title="Select the report date for which the results have to be compared">
                  <span>Select Report 2</span>
                  </Tooltip>
                  <Select
                    options={reporttwodata}
                    labelField="completed_at"
                    valueField="id"
                    onChange={(values) =>setSelectsecondval(values)}
                  />
                </div>
           
               
              </div>

             
              </div>

              <div className="col">
                <span className="text-error">{validation.dateerror1}</span>
              </div>
           
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="run_rpt_btn rn_rpt_btn" onClick={reportNow} style={{cursor:'pointer'}} >
            Submit
          </button>
          <button style={{cursor:'pointer'}} onClick={closeModal}>close</button>
        </DialogActions>
      </Dialog>
      {/* <Dialog
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Regression Form"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">
              <div className="col">
                <div>
                  <span>Domain 1</span>
                  <Select
                    options={domainval}
                    labelField="domain__name"
                    valueField="id"
                    onChange={dataChange}
                  />
                </div>
           
                <div>
                  <span>Select Report</span>
                  <Select
                    options={reportdata}
                    labelField="completed_at"
                    valueField="id"
                    onChange={(values) => setSelectval(values)}
                  />
                </div>
              </div>
              <div className="col">
                <div>
                  <span>Domain 2</span>
                  <Select
                    options={domainval}
                    labelField="domain__name"
                    valueField="id"
                    onChange={datavalChange}
                  />
                </div>
           
                <div>
                  <span>Select Report</span>
                  <Select
                    options={reporttwodata}
                    labelField="completed_at"
                    valueField="id"
                    onChange={(values) =>setSelectsecondval(values)}
                  />
                </div>
              </div>
              <div className="col">
                <span className="text-error">{validation.dateerror}</span>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="run_rpt_btn rn_rpt_btn" onClick={reportNow}>
            Submit
          </button>
          <button onClick={closeModal}>close</button>
        </DialogActions>
      </Dialog> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Error Message "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            No Data Found
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
      </>
  );
}
