import * as React from "react";
import  { useLayoutEffect,  useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import "./style.css";
import BellIcon from "./Asset/New_Assert/notification.png";
import Mailicon from "./Asset/New_Assert/Gmail.png";
import EsOLogo from "./Asset/Logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SetDomainHeader } from "../../Store/DomainData";
import { GetListOfDomains } from "../../Store/ListOfDomains";
import { SetUserToken } from "../../Store/UserTokenSlice";
import { SetAuthToken } from "../../Store/ApiAuthKey";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { LineElement } from "chart.js";
import { AppContext } from "../../Context/Context";

const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniHeader() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [headerdata,setHeaderdata] =useState("1");
  const [handledomain,setHandledomain] =useState();
  const {topactionnotify, setTopactionnotify} = useContext(AppContext);
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);



  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let querystring = useParams();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.usertoken.value.eso_user_token;
  // if(headerdata==1)
  // setHandledomain(esodata.domainlist?.value["0"]?.id)
 
  const triggerfunction = (value) => {
    dispatch(SetDomainHeader({ header_selected: value }));
   
    var locationCurrentUrl = window.location.href;
    var except1 = /content-detail\/[\d]+/g;
    var except2 = /spell-checker-detail\/[\d]+/g;
    var except3 = /plagiarisam-detail\/[\d]+/g;
    var except4 = /plagiarisam-scan-detail\/[\d]+/g;
    var except5 = /schema-detail\/[\d]+/g;
    var except6 = /performance-detail\/[\d]+/g;
    var except7 = /source-code-detail\/[\d]+/g;
    var except8 = /source\/[\d]+/g;
    var except9 = /out-bound\/[\d]+/g;
    var except10 = /edit-keyword\/[\d]+/g;
    var except11 = /accessbility-report-detail\/[\d]+/g;
    var except12 = /pagehealth-detail\/[\d]+/g;


    

    if (except1.test(locationCurrentUrl)) {
      window.location.href = `${process.env.REACT_APP_DOMAIN}analysis-summary`;
    } else if (except2.test(locationCurrentUrl)) {
      window.location.href = `${process.env.REACT_APP_DOMAIN}spell-checker-list`;
    } else if (
      except3.test(locationCurrentUrl) ||
      except4.test(locationCurrentUrl)
    ) {
      window.location.href = `${process.env.REACT_APP_DOMAIN}plagiarism-list`;
    } else if (except5.test(locationCurrentUrl)) {
      window.location.href = `${process.env.REACT_APP_DOMAIN}schema-analysis`;
    } else if (except6.test(locationCurrentUrl)) {
      window.location.href = `${process.env.REACT_APP_DOMAIN}performance-list`;
    } else if (except7.test(locationCurrentUrl)) {
      window.location.href = `${process.env.REACT_APP_DOMAIN}source-code-list`;
    } else if (
      except8.test(locationCurrentUrl) ||
      except9.test(locationCurrentUrl)
    ) {
      window.location.href = `${process.env.REACT_APP_DOMAIN}internal-link-checker`;
    }else if (except10.test(locationCurrentUrl)) {
      window.location.href = `${process.env.REACT_APP_DOMAIN}keywords`;
    } 
    else if (except11.test(locationCurrentUrl)) {
      window.location.href = `${process.env.REACT_APP_DOMAIN}accessbility-report-list`;
    } 
    else if (except12.test(locationCurrentUrl)) {
      window.location.href = `${process.env.REACT_APP_DOMAIN}page-health/3/`;
    } 
     else {
      window.location.reload();
    }
    //
  };

  const GetLists = async () => {
    dispatch(GetListOfDomains());
  };

  useEffect(() => {
    // if(headerdata=="1"){
    //   setHeaderdata("2")
    //   dispatch(SetDomainHeader({ header_selected:   handledomain }));
    
    // }
    GetLists();
  }, []);

  const [HeaderDomainErrMsg, SetHeaderDomainErrMsg] = useState(false);

  useEffect(() => {
    if (
      esodata.domaindata.value.header_selected === null ||
      esodata.domaindata.value.header_selected === "" ||
      esodata.domaindata.value.header_selected === undefined
    ) {
      SetHeaderDomainErrMsg(true);
    }
  }, []);


    // if (HeaderDomainErrMsg === true) {
    //   toast.error("Please choose the domain", {
    //     position: "top-center",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "light",
    //   });
    // }
  // }, [HeaderDomainErrMsg]);

  const TriggerLogout = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/logout/`,
        {
          headers: {
            Authorization: `Token ${ApiToken}`,
          },
        }
      );

      if (datavalue.data) {
        
        localStorage.setItem("eso_auth_token", "");
        localStorage.setItem("eso_user_token", "");
        dispatch(SetUserToken({ eso_user_token: "" }));
        dispatch(SetAuthToken({ eso_auth_token: "" }));
        localStorage.clear()
        // localStorage.clear();
        navigate("/login");
      }
    } catch (err) {
      localStorage.setItem("eso_auth_token", "");
      localStorage.setItem("eso_user_token", "");
      dispatch(SetUserToken({ eso_user_token: "" }));
      dispatch(SetAuthToken({ eso_auth_token: "" }))
      localStorage.clear()
      navigate("/login");

      // toast.error('Something went wrong! Please Try Again', {
      //   position: "top-center",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  };

  const OpenSmallPoP = () => {
    SetshowDrop(!showDrop);
  };

  const [showDrop, SetshowDrop] = React.useState(false);


  const NavigateTo = (e) => {
    SetshowDrop(false);

    const Link = e.target.getAttribute("data-link");

    navigate(Link);
  };
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') || 
    (event.target.classList[0] === 'nav_key_txt') || 
    (event.target.classList[0] === 'cnt_hdr_top_wrap') ||(event.target.classList[0] === 'react-dropdown-select') 
    ||(event.target.classList[0] === 'react-dropdown-select-content') 
    ||(event.target.classList[0] === 'react-dropdown-select-item') 
    || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap')   || (event.target.classList[0] === 'App')  
    || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
     || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row') 
     || (event.target.classList[0] === 'MuiDataGrid-cell')  || (event.target.classList[0] === 'MuiDataGrid-cellContent')
     || (event.target.classList[0] === 'header_inner_btm_wrap') ) {
      SetshowDrop(false)
    }
});

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className={`header-outer-wrap ${isMenuExpanded ? "blur overflow-hidden" : ""}`} style={{position:"sticky", width:"100%"}}>
      <div className="header_wrap">
        <div className="header_inner_top_wrap">
          {/* <div className="logo_wrap">
            <img src={EsOLogo} alt="eso_logo" />
          </div> */}
          <div className="drp_outer_wrap">
            <div className="drp_inner_tp_wrap">
        
              <select
                name="EGS"
                onChange={(e) => {
                  triggerfunction(e.target.value);
                }}
                id="drp_inner_top_wrap"
                className="select_category"
                value={esodata.domaindata.value.header_selected}
               
              >
                <option value="" disabled selected>
                  Select Domain
                </option>
                {esodata.domainlist.value.map((value, index) => (
                  <option key={index} value={value.id}>
                    {value.domain__name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
           {/* <Link to="/top-action-item">
           <div class="bell-container">
        
        <div class="bell">
        &#128276; 
            <span class="bell-notification">{topactionnotify}</span>
           
        </div>
        
    </div>
    </Link> */}
        <div className="header_inner_btm_wrap">
          {/* <div className="bx_wrap_days"> */}
            {/* <p>Next report will be in 7 days</p> */}
          {/* </div> */}
          <Link to="/top-action-item">
           <div class="bell-container">
        
        <div class="bell">
         &#128276; 
            <span class="bell-notification">{topactionnotify}</span>
           
        </div>
        
    </div>
    </Link>
          <div className="top_icons_grp">
         
            {/* <img src={BellIcon} className="bell_img" alt="icons" />
            <img src={Mailicon} className="mail_img" alt="icons" /> */}


            <span
              onClick={OpenSmallPoP}
              style={{ paddingRight: "6px" }}
              className="account_set_wrap"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                clip-rule="evenodd"
                fill-rule="evenodd"
                height="512"
                stroke-linejoin="round"
                stroke-miterlimit="2"
                viewBox="0 0 24 24"
                width="512"
              >
                <g id="Icon">
                  <circle cx="11.5" cy="6.744" r="5.5" />
                  <path d="m17.5 13.938c-1.966 0-3.562 1.596-3.562 3.562s1.596 3.563 3.562 3.563 3.563-1.597 3.563-3.563-1.597-3.562-3.563-3.562zm0 1.5c1.138 0 2.063.924 2.063 2.062s-.925 2.063-2.063 2.063-2.063-.925-2.063-2.063.925-2.062 2.063-2.062z" />
                  <path d="m18.25 14.687v-1.687c0-.414-.336-.75-.75-.75s-.75.336-.75.75v1.688c0 .413.336.75.75.75.414-.001.75-.337.75-.751z" />
                  <path d="m20.019 16.042 1.193-1.194c.293-.292.293-.768 0-1.06-.292-.293-.768-.293-1.06 0l-1.194 1.193c-.292.293-.292.768 0 1.061.293.292.768.292 1.061 0z" />
                  <path d="m20.312 18.25h1.688c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-1.688c-.413 0-.749.336-.749.75-.001.414.336.75.749.75z" />
                  <path d="m18.958 20.019 1.194 1.193c.292.293.768.293 1.06 0 .293-.292.293-.768 0-1.06l-1.193-1.194c-.293-.292-.768-.292-1.061 0-.292.293-.292.768 0 1.061z" />
                  <path d="m16.75 20.312v1.688c0 .414.336.75.75.75s.75-.336.75-.75v-1.688c0-.413-.336-.749-.75-.75-.414 0-.75.337-.75.75z" />
                  <path d="m14.981 18.958-1.193 1.194c-.293.292-.293.768 0 1.06.292.293.768.293 1.06 0l1.194-1.193c.292-.293.292-.768 0-1.061-.293-.292-.768-.292-1.061 0z" />
                  <path d="m14.687 16.75h-1.687c-.414 0-.75.336-.75.75s.336.75.75.75h1.687c.414 0 .751-.336.75-.75 0-.414-.336-.75-.75-.75z" />
                  <path d="m16.042 14.981-1.194-1.193c-.292-.293-.768-.293-1.06 0-.293.292-.293.768 0 1.06l1.193 1.194c.293.292.768.292 1.061 0 .292-.293.292-.768 0-1.061z" />
                  <path d="m12.936 21.756c-.534-.686-.486-1.681.145-2.311l.194-.195h-.275c-.966 0-1.75-.784-1.75-1.75s.784-1.75 1.75-1.75h.275l-.194-.195c-.656-.655-.682-1.704-.078-2.391-.49-.038-.992-.058-1.503-.058-3.322 0-6.263.831-8.089 2.076-1.393.95-2.161 2.157-2.161 3.424v1.45c0 .451.179.884.498 1.202.319.319.751.498 1.202.498z" />
                </g>
              </svg>
            </span>
            {/* <img src={SettingPng} onClick={OpenSmallPoP} style={{paddingLeft:'6px'}} className="mail_img" alt="icons" /> */}
            {/* <img src={LogoutIcon} onClick={TriggerLogout} style={{paddingLeft:'6px'}} className="mail_img" alt="icons" /> */}
          </div>
      
        </div>

        {showDrop === true ? (
          <div className="drop_menu">
            <div
              className="menu"
              data-link="/change-password"
              onClick={NavigateTo}
            >
              <span className="pass_img">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 100 100"
                >
                  <g id="Page-1">
                    <g
                      id="_010---Secure-Password"
                      data-name="010---Secure-Password"
                    >
                      <path
                        id="Shape"
                        class="cls-1"
                        d="m79.22,42.6c0-2.54-2.06-4.59-4.59-4.59H22.56c-2.54,0-4.59,2.06-4.59,4.59v9.19h61.25v-9.19Zm-52.06,1.53h-3.06v3.06c0,.85-.69,1.53-1.53,1.53s-1.53-.69-1.53-1.53v-4.59c0-.85.69-1.53,1.53-1.53h4.59c.85,0,1.53.69,1.53,1.53s-.69,1.53-1.53,1.53Z"
                      />
                      <path
                        id="Shape-2"
                        data-name="Shape"
                        class="cls-1"
                        d="m17.97,85.47c0,2.54,2.06,4.59,4.59,4.59h52.06c2.54,0,4.59-2.06,4.59-4.59v-9.19H17.97v9.19Z"
                      />
                      <path
                        id="Shape-3"
                        data-name="Shape"
                        class="cls-1"
                        d="m89.93,54.85H7.25c-.85,0-1.53.69-1.53,1.53v15.31c0,.85.69,1.53,1.53,1.53h82.69c.85,0,1.53-.69,1.53-1.53v-15.31c0-.85-.69-1.53-1.53-1.53Zm-69.59,10.98c.7.47.89,1.42.42,2.12-.47.7-1.42.89-2.12.42l-2.21-1.48v1.73c0,.85-.69,1.53-1.53,1.53s-1.53-.69-1.53-1.53v-1.73l-2.21,1.48c-.7.47-1.65.28-2.12-.42s-.28-1.65.42-2.12l2.69-1.79-2.69-1.79c-.7-.47-.89-1.42-.42-2.12s1.42-.89,2.12-.42l2.21,1.48v-1.73c0-.85.69-1.53,1.53-1.53s1.53.69,1.53,1.53v1.73l2.21-1.48c.46-.3,1.04-.34,1.53-.1s.81.73.85,1.27-.22,1.07-.68,1.37l-2.69,1.79,2.69,1.79Zm16.84,0c.7.47.89,1.42.42,2.12-.47.7-1.42.89-2.12.42l-2.21-1.48v1.73c0,.85-.69,1.53-1.53,1.53s-1.53-.69-1.53-1.53v-1.73l-2.21,1.48c-.7.47-1.65.28-2.12-.42-.47-.7-.28-1.65.42-2.12l2.69-1.79-2.68-1.79c-.7-.47-.89-1.42-.42-2.12.47-.7,1.42-.89,2.12-.42l2.21,1.48v-1.73c0-.85.69-1.53,1.53-1.53s1.53.69,1.53,1.53v1.73l2.21-1.48c.46-.3,1.04-.34,1.53-.1s.81.73.85,1.27-.22,1.07-.68,1.37l-2.69,1.79,2.69,1.79Zm16.84,0c.7.47.89,1.42.42,2.12-.47.7-1.42.89-2.12.42l-2.21-1.48v1.73c0,.85-.69,1.53-1.53,1.53s-1.53-.69-1.53-1.53v-1.73l-2.21,1.48c-.7.47-1.65.28-2.12-.42-.47-.7-.28-1.65.42-2.12l2.69-1.79-2.68-1.79c-.7-.47-.89-1.42-.42-2.12.47-.7,1.42-.89,2.12-.42l2.21,1.48v-1.73c0-.85.69-1.53,1.53-1.53s1.53.69,1.53,1.53v1.73l2.21-1.48c.46-.3,1.04-.34,1.53-.1.49.24.81.73.85,1.27s-.22,1.07-.68,1.37l-2.69,1.79,2.69,1.79Zm16.84,0c.7.47.89,1.42.42,2.12-.47.7-1.42.89-2.12.42l-2.21-1.48v1.73c0,.85-.69,1.53-1.53,1.53s-1.53-.69-1.53-1.53v-1.73l-2.21,1.48c-.7.47-1.65.28-2.12-.42-.47-.7-.28-1.65.42-2.12l2.69-1.79-2.68-1.79c-.7-.47-.89-1.42-.42-2.12.47-.7,1.42-.89,2.12-.42l2.21,1.48v-1.73c0-.85.69-1.53,1.53-1.53s1.53.69,1.53,1.53v1.73l2.21-1.48c.46-.3,1.04-.34,1.53-.1s.81.73.85,1.27-.22,1.07-.68,1.37l-2.69,1.79,2.69,1.79Zm15.99,4.34h-9.19c-.85,0-1.53-.69-1.53-1.53s.69-1.53,1.53-1.53h9.19c.85,0,1.53.69,1.53,1.53s-.69,1.53-1.53,1.53Z"
                      />
                      <path
                        id="Shape-4"
                        data-name="Shape"
                        class="cls-1"
                        d="m31.75,27.29c0-9.3,7.54-16.84,16.84-16.84s16.84,7.54,16.84,16.84v7.66h6.12v-7.66c0-12.68-10.28-22.97-22.97-22.97s-22.97,10.28-22.97,22.97v7.66h6.12v-7.66Z"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      class="cls-1"
                      d="m42.39,21.29c-2.21-.28-4.23,1.14-4.74,3.25-.51,2.1.63,4.27,2.63,5.04,2.07.79,4.43-.07,5.39-2.04.88-1.81,1.67-3.66,2.51-5.49.17-.38.31-.77.53-1.12.38-.61,1.15-.8,1.79-.49.61.3.98,1.04.71,1.66-1.1,2.45-2.1,4.96-3.41,7.3-1.58,2.82-5.18,3.95-8.28,2.88-3.17-1.1-5.08-4.13-4.76-7.55.3-3.14,2.89-5.81,6.06-6.24,1.98-.27,3.75.22,5.39,1.41-1.11,2.27-2.18,4.5-3.29,6.72-.35.7-1.17.9-1.85.55-.67-.34-.96-1.11-.63-1.83.53-1.16,1.11-2.3,1.66-3.45.09-.18.17-.36.27-.6Z"
                    />
                    <path
                      class="cls-1"
                      d="m54.04,29.74c2.97.31,5-2,4.92-4.37-.08-2.32-1.8-4.08-4.17-4.22-.3-.02-.45-.08-.55-.4-1.01-3.08-4.38-4.16-7.01-2.27-.06.04-.12.08-.14.09-.89-.48-1.75-.94-2.8-1.51.3-.23.61-.44.89-.68,3.35-2.86,8.48-2.16,10.93,1.52.25.38.55.59.98.75,2.77,1.07,4.44,3.08,4.76,6.03.31,2.98-.88,5.37-3.43,6.92-2.56,1.55-5.18,1.42-7.73-.15-.34-.2-.4-.39-.23-.76.87-1.87,1.71-3.76,2.57-5.64.4-.89,1.18-1.23,1.96-.89.76.34,1.02,1.16.63,2.06-.51,1.17-1.03,2.33-1.55,3.51Z"
                    />
                  </g>
                </svg>
              </span>{" "}
              Change Password
            </div>
            <div
              className="menu"
              data-link="/user-list"
              onClick={NavigateTo}
            >
              <span className="pass_img">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 100 100"
                >
                  <g>
                    <path
                      class="cls-1"
                      d="m22.07,13.45h55.95c1.72,0,3.11-1.39,3.11-3.11s-1.39-3.11-3.11-3.11H22.07c-1.72,0-3.11,1.39-3.11,3.11s1.39,3.11,3.11,3.11Z"
                    />
                    <path
                      class="cls-1"
                      d="m15.86,25.88h68.38c1.72,0,3.11-1.39,3.11-3.11s-1.39-3.11-3.11-3.11H15.86c-1.72,0-3.11,1.39-3.11,3.11s1.39,3.11,3.11,3.11Z"
                    />
                  </g>
                  <path
                    class="cls-1"
                    d="m84.24,32.1H15.86c-5.15,0-9.32,4.17-9.32,9.32v43.51c0,5.15,4.17,9.32,9.32,9.32h68.38c5.15,0,9.32-4.17,9.32-9.32v-43.51c0-5.15-4.17-9.32-9.32-9.32Zm-56,52.8c-8.05-2.78-12.89-10.47-12.06-19.15.77-7.97,7.34-14.73,15.38-15.84,5.02-.69,9.51.57,13.67,3.58-2.81,5.76-5.53,11.43-8.34,17.04-.89,1.77-2.97,2.29-4.68,1.4-1.69-.87-2.43-2.83-1.59-4.64,1.35-2.94,2.81-5.84,4.22-8.76.22-.46.42-.92.69-1.52-5.62-.72-10.74,2.88-12.03,8.23-1.28,5.32,1.6,10.84,6.68,12.78,5.24,2.01,11.23-.18,13.67-5.18,2.23-4.58,4.25-9.27,6.36-13.92.43-.95.8-1.95,1.34-2.84.96-1.55,2.93-2.04,4.53-1.24,1.54.76,2.5,2.64,1.79,4.22-2.78,6.22-5.32,12.59-8.64,18.51-4.01,7.16-13.13,10.03-20.99,7.31Zm47.87-1.7c-6.49,3.94-13.14,3.59-19.62-.37-.85-.52-1.02-.98-.57-1.94,2.22-4.75,4.34-9.54,6.51-14.31,1.02-2.25,3-3.12,4.96-2.25,1.94.86,2.59,2.95,1.59,5.22-1.29,2.96-2.6,5.9-3.92,8.9,7.54.77,12.69-5.07,12.49-11.09-.19-5.88-4.57-10.34-10.58-10.7-.76-.05-1.13-.19-1.4-1-2.55-7.8-11.11-10.55-17.79-5.76-.15.11-.31.21-.36.23-2.26-1.21-4.43-2.38-7.11-3.82.77-.59,1.54-1.11,2.25-1.72,8.49-7.25,21.51-5.47,27.71,3.85.65.97,1.4,1.5,2.48,1.91,7.03,2.7,11.27,7.81,12.06,15.3.79,7.55-2.22,13.61-8.71,17.55Z"
                  />
                </svg>
              </span>
              User Management
            </div>
            <div className="menu" data-link="/my-account" onClick={NavigateTo}>
              <span className="pass_img">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 100 100"
                >
                  <path
                    class="cls-1"
                    d="m45.95,45.31c5.7,0,10.64-2.04,14.67-6.08,4.03-4.03,6.08-8.97,6.08-14.67s-2.04-10.63-6.08-14.67c-4.03-4.03-8.97-6.08-14.67-6.08s-10.64,2.04-14.67,6.08-6.08,8.97-6.08,14.67,2.05,10.64,6.08,14.67c4.03,4.03,8.97,6.08,14.67,6.08Z"
                  />
                  <path
                    class="cls-1"
                    d="m82.25,70.05c-.12-1.68-.35-3.51-.7-5.44-.35-1.95-.8-3.79-1.34-5.47-.56-1.74-1.31-3.46-2.25-5.1-.97-1.71-2.11-3.2-3.39-4.42-1.34-1.28-2.98-2.31-4.87-3.06-1.89-.75-3.98-1.13-6.22-1.13-.88,0-1.73.36-3.37,1.43-1.01.66-2.19,1.42-3.51,2.26-1.13.72-2.66,1.39-4.54,2-1.84.6-3.71.9-5.56.9s-3.72-.3-5.56-.9c-1.89-.61-3.41-1.28-4.54-2-1.31-.84-2.49-1.6-3.52-2.27-1.64-1.07-2.49-1.43-3.37-1.43-2.24,0-4.33.38-6.22,1.13-1.89.75-3.53,1.78-4.87,3.06-1.28,1.22-2.42,2.71-3.39,4.42-.94,1.65-1.69,3.36-2.25,5.1-.54,1.68-.99,3.52-1.34,5.47-.35,1.93-.58,3.76-.7,5.44-.11,1.65-.17,3.36-.17,5.09,0,4.5,1.43,8.14,4.25,10.82,2.78,2.65,6.47,3.99,10.95,3.99h41.47c4.48,0,8.16-1.34,10.95-3.99,2.82-2.68,4.25-6.32,4.25-10.82,0-1.74-.06-3.45-.17-5.09Z"
                  />
                </svg>
              </span>
              My account
            </div>
            <div
              className="menu"
              data-link="/subscription"
              onClick={NavigateTo}
            >
              <span className="pass_img">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 100 100"
                >
                  <g>
                    <path
                      class="cls-1"
                      d="m22.07,13.45h55.95c1.72,0,3.11-1.39,3.11-3.11s-1.39-3.11-3.11-3.11H22.07c-1.72,0-3.11,1.39-3.11,3.11s1.39,3.11,3.11,3.11Z"
                    />
                    <path
                      class="cls-1"
                      d="m15.86,25.88h68.38c1.72,0,3.11-1.39,3.11-3.11s-1.39-3.11-3.11-3.11H15.86c-1.72,0-3.11,1.39-3.11,3.11s1.39,3.11,3.11,3.11Z"
                    />
                  </g>
                  <path
                    class="cls-1"
                    d="m84.24,32.1H15.86c-5.15,0-9.32,4.17-9.32,9.32v43.51c0,5.15,4.17,9.32,9.32,9.32h68.38c5.15,0,9.32-4.17,9.32-9.32v-43.51c0-5.15-4.17-9.32-9.32-9.32Zm-56,52.8c-8.05-2.78-12.89-10.47-12.06-19.15.77-7.97,7.34-14.73,15.38-15.84,5.02-.69,9.51.57,13.67,3.58-2.81,5.76-5.53,11.43-8.34,17.04-.89,1.77-2.97,2.29-4.68,1.4-1.69-.87-2.43-2.83-1.59-4.64,1.35-2.94,2.81-5.84,4.22-8.76.22-.46.42-.92.69-1.52-5.62-.72-10.74,2.88-12.03,8.23-1.28,5.32,1.6,10.84,6.68,12.78,5.24,2.01,11.23-.18,13.67-5.18,2.23-4.58,4.25-9.27,6.36-13.92.43-.95.8-1.95,1.34-2.84.96-1.55,2.93-2.04,4.53-1.24,1.54.76,2.5,2.64,1.79,4.22-2.78,6.22-5.32,12.59-8.64,18.51-4.01,7.16-13.13,10.03-20.99,7.31Zm47.87-1.7c-6.49,3.94-13.14,3.59-19.62-.37-.85-.52-1.02-.98-.57-1.94,2.22-4.75,4.34-9.54,6.51-14.31,1.02-2.25,3-3.12,4.96-2.25,1.94.86,2.59,2.95,1.59,5.22-1.29,2.96-2.6,5.9-3.92,8.9,7.54.77,12.69-5.07,12.49-11.09-.19-5.88-4.57-10.34-10.58-10.7-.76-.05-1.13-.19-1.4-1-2.55-7.8-11.11-10.55-17.79-5.76-.15.11-.31.21-.36.23-2.26-1.21-4.43-2.38-7.11-3.82.77-.59,1.54-1.11,2.25-1.72,8.49-7.25,21.51-5.47,27.71,3.85.65.97,1.4,1.5,2.48,1.91,7.03,2.7,11.27,7.81,12.06,15.3.79,7.55-2.22,13.61-8.71,17.55Z"
                  />
                </svg>
              </span>
              Subscriptions
            </div>
            <div className="menu" data-link="/domain-list" onClick={NavigateTo}>
              <span className="pass_img">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 100 100"
                >
                  <g>
                    <path
                      class="cls-1"
                      d="m88.91,60.06c-4.17-1.75-6.59-4.84-6.59-8.07s2.42-6.19,6.59-8.07c.54-.27.94-.94.81-1.48-1.21-5.11-3.09-9.82-5.79-14.13-.4-.54-1.08-.81-1.61-.54-4.17,1.61-8.07,1.21-10.36-1.08s-2.69-6.19-1.08-10.36c.27-.54,0-1.21-.54-1.61-4.31-2.69-9.01-4.71-13.99-5.92-.67-.13-1.35.13-1.48.81-1.75,4.17-4.84,6.59-8.07,6.59s-6.19-2.42-8.07-6.59c-.27-.54-.94-.94-1.48-.81-4.98,1.08-9.69,3.09-13.99,5.79-.81.54-.94,1.21-.81,1.88,1.61,4.17,1.21,8.07-1.08,10.36s-6.19,2.69-10.36,1.08c-.54-.27-1.21,0-1.61.54-2.69,4.17-4.71,9.01-5.92,14.13-.13.67.13,1.35.81,1.48,4.17,1.75,6.59,4.84,6.59,8.07s-2.42,6.32-6.59,8.07c-.54.27-.94.94-.81,1.48,1.08,4.98,3.09,9.69,5.79,14.13.54.4,1.21.67,1.88.4,4.17-1.61,8.07-1.21,10.36.94,2.29,2.29,2.69,6.19,1.08,10.36-.27.54,0,1.21.54,1.61,4.44,2.69,9.15,4.71,13.99,5.92.67.13,1.21-.13,1.48-.81,1.75-4.04,4.84-6.59,8.07-6.59s6.32,2.56,8.07,6.59c.27.54.67.81,1.21.81h.27c4.98-1.08,9.82-3.09,13.99-5.79.54-.4.81-1.08.54-1.61-1.61-4.31-1.21-8.07,1.08-10.36s6.05-2.69,10.36-.94c.67.27,1.35,0,1.61-.54,2.69-4.31,4.71-9.15,5.79-14.13.27-.67-.13-1.35-.67-1.61h0Zm-42.25,22.33c-16.68,0-30.41-13.59-30.41-30.41s13.72-30.41,30.41-30.41,30.41,13.72,30.41,30.41-13.72,30.41-30.41,30.41Z"
                    />
                    <path
                      class="cls-1"
                      d="m60.38,33.69h-29.2c-.81,0-1.35.67-1.35,1.35v8.88c0,.67.54,1.35,1.35,1.35h29.2c.67,0,1.35-.67,1.35-1.35v-8.88c0-.81-.67-1.35-1.35-1.35Zm-17.76,7.13h-6.05c-.67,0-1.35-.67-1.35-1.35,0-.81.67-1.35,1.35-1.35h6.05c.67,0,1.35.54,1.35,1.35,0,.67-.67,1.35-1.35,1.35Z"
                    />
                    <path
                      class="cls-1"
                      d="m61.73,50.78v-2.42c0-.67-.54-1.35-1.35-1.35h-29.2c-.67,0-1.35.67-1.35,1.35v8.88c0,.67.67,1.35,1.35,1.35h20.32v1.75h-20.32c-.67,0-1.35.67-1.35,1.35v8.88c0,.67.67,1.35,1.35,1.35h29.2c.81,0,1.35-.67,1.35-1.35v-2.29c4.31-.67,7.53-4.31,7.53-8.75s-3.23-8.07-7.53-8.75h0Zm-19.11,3.36h-6.05c-.67,0-1.35-.67-1.35-1.35s.67-1.35,1.35-1.35h6.05c.67,0,1.35.67,1.35,1.35,0,.81-.67,1.35-1.35,1.35Zm0,13.32h-6.05c-.67,0-1.35-.54-1.35-1.35,0-.67.67-1.35,1.35-1.35h6.05c.67,0,1.35.67,1.35,1.35,0,.81-.67,1.35-1.35,1.35Zm17.76-1.61c-2.69,0-5.25-1.88-6.05-4.44-.13-.54-.27-1.21-.27-1.75,0-.67.13-1.35.27-1.88.81-2.56,3.23-4.31,5.92-4.31,3.5,0,6.19,2.83,6.19,6.19.13,3.36-2.56,6.19-6.05,6.19h0Z"
                    />
                  </g>
                  <g>
                    <path
                      class="cls-1"
                      d="m58.35,58.44c-.75-.1-1.44.39-1.61,1.1-.17.71.21,1.45.89,1.71.7.27,1.5-.02,1.83-.69.3-.61.57-1.24.85-1.86.06-.13.11-.26.18-.38.13-.21.39-.27.61-.17.21.1.33.35.24.56-.37.83-.71,1.68-1.15,2.47-.54.96-1.76,1.34-2.81.98-1.08-.37-1.72-1.4-1.61-2.56.1-1.07.98-1.97,2.06-2.12.67-.09,1.27.08,1.83.48-.38.77-.74,1.53-1.12,2.28-.12.24-.4.31-.63.19-.23-.12-.32-.38-.21-.62.18-.39.38-.78.56-1.17.03-.06.06-.12.09-.2Z"
                    />
                    <path
                      class="cls-1"
                      d="m62.3,61.3c1.01.1,1.7-.68,1.67-1.48-.03-.79-.61-1.38-1.41-1.43-.1,0-.15-.03-.19-.13-.34-1.04-1.49-1.41-2.38-.77-.02.01-.04.03-.05.03-.3-.16-.59-.32-.95-.51.1-.08.21-.15.3-.23,1.13-.97,2.87-.73,3.7.51.09.13.19.2.33.26.94.36,1.51,1.04,1.61,2.05.11,1.01-.3,1.82-1.16,2.35-.87.53-1.76.48-2.62-.05-.11-.07-.14-.13-.08-.26.3-.63.58-1.28.87-1.91.14-.3.4-.42.66-.3.26.11.35.39.21.7-.17.4-.35.79-.52,1.19Z"
                    />
                  </g>
                </svg>
              </span>
              Domain Management
            </div>
            <div className="menu" onClick={TriggerLogout}>
              <span className="pass_img">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 100 100"
                >
                  <path
                    class="cls-2"
                    d="m45.95,49.04c5.7,0,10.64-2.04,14.67-6.08,4.03-4.03,6.08-8.97,6.08-14.67s-2.04-10.63-6.08-14.67c-4.03-4.03-8.97-6.08-14.67-6.08s-10.64,2.04-14.67,6.08-6.08,8.97-6.08,14.67,2.05,10.64,6.08,14.67c4.03,4.03,8.97,6.08,14.67,6.08Z"
                  />
                  <path
                    class="cls-2"
                    d="m46.49,55.75c-1.85,0-3.72-.3-5.56-.9-1.89-.61-3.41-1.28-4.54-2-1.31-.84-2.49-1.6-3.52-2.27-1.64-1.07-2.49-1.43-3.37-1.43-2.24,0-4.33.38-6.22,1.13-1.89.75-3.53,1.78-4.87,3.06-1.28,1.22-2.42,2.71-3.39,4.42-.94,1.65-1.69,3.36-2.25,5.1-.54,1.68-.99,3.52-1.34,5.47-.35,1.93-.58,3.76-.7,5.44-.11,1.65-.17,3.36-.17,5.09,0,4.5,1.43,8.14,4.25,10.82,2.78,2.65,6.47,3.99,10.95,3.99h20.62"
                  />
                  <g>
                    <path
                      class="cls-1"
                      d="m73.55,55.25c-1.62-1.84-4-3-6.66-3h-6.37c-4.9,0-8.87,3.97-8.87,8.87v22.3c0,4.9,3.97,8.87,8.87,8.87h6.37c2.65,0,5.03-1.17,6.66-3,.92-1.04.82-2.62-.22-3.53-1.04-.92-2.62-.82-3.53.22-.71.81-1.75,1.31-2.9,1.31h-6.37c-2.14,0-3.87-1.73-3.87-3.87v-22.3c0-2.14,1.73-3.87,3.87-3.87h6.37c1.15,0,2.19.5,2.9,1.31.92,1.04,2.5,1.14,3.53.22,1.04-.92,1.14-2.5.22-3.53h0Z"
                    />
                    <path
                      class="cls-1"
                      d="m83.98,69.84c.98.98.98,2.56,0,3.54l-6.68,6.68c-.98.98-2.56.98-3.54,0s-.98-2.56,0-3.54l6.68-6.68c.98-.98,2.56-.98,3.54,0Z"
                    />
                    <path
                      class="cls-1"
                      d="m83.98,73.38c.98-.98.98-2.56,0-3.54l-6.68-6.68c-.98-.98-2.56-.98-3.54,0s-.98,2.56,0,3.54l6.68,6.68c.98.98,2.56.98,3.54,0Z"
                    />
                    <path
                      class="cls-1"
                      d="m61.34,71.61c0-1.38,1.12-2.5,2.5-2.5h16.69c1.38,0,2.5,1.12,2.5,2.5s-1.12,2.5-2.5,2.5h-16.69c-1.38,0-2.5-1.12-2.5-2.5Z"
                    />
                  </g>
                </svg>
              </span>
              Logout
            </div>
          </div>
        ) : null}
      </div>
      </div>
    </>
  );
}
