import React, { useState, useEffect, useRef ,useContext} from "react";
import "./Siteauditstyle.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import Siteauditlists from "./Siteauditlists";
import Onpagecrawllist from "./Onpagecrawllist";
import Pagehealthlists from "./Pagehealthlists";
import CompetitorTest from "./CompetitorTest";
import { Link, useParams } from "react-router-dom";
import RunReportNow from "../RunReportNow";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import axios from "axios";
import Loader from "../CommonLoader";
import { Commonfun } from './../Commonfun';
import { useNavigate } from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../../Context/Context";
export default function Siteonanalysis() {
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectval, setSelectval] = useState("");
  const [fielddata, setFielddata] = useState("1");
  const [menubutval,setMenubutval]=useState("1")
  const [buttnval,setButtnval]=useState("1")
  const [buttnclickval,setButtnclickval]=useState("1")
  const [dataurl,setdataurl]=useState("1")

  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [pagename,setPagename]=useState("On Page Crawl Data")
  const [faqData,setfaqData]=useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval,SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();
  const [jobDate, setJobdate] = React.useState(false);
  let querystring = useParams();
  let domain_id = querystring.id;

  const navigate = useNavigate();

  const  menuChange = e => {
    e.preventDefault();
    setMenubutval(e.target.id)
    setButtnval(e.target.id)
    if(e.target.id==1)
    setPagename("Site Audit")
    else if (e.target.id==2)
    setPagename("On Page Crawl")
    else if (e.target.id==3)
    setPagename("Page Health")
    else if (e.target.id==4)
    setPagename("Competitor Analysis")
  };
  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  useEffect (() =>{
    if(fielddata==1)
    setFielddata("all");
    else if(fielddata==2)
    setFielddata("missing_title") 
    else if(fielddata==14)
    setFielddata("missing_canonical") 
    else if(fielddata==15)
    setFielddata("missing_description")
    else if(fielddata==3)
    setFielddata("mismatch_canonical")    
    else if(fielddata==4)
    setFielddata("duplicate_title")   
    else if(fielddata==5)
    setFielddata("duplicate_description")     
    else if(fielddata==6)
    setFielddata("images") 
    else if(fielddata==18)
    setFielddata("missing_alt") 
    else if(fielddata==16)
    setFielddata("missing_h1") 
    else if(fielddata==7)
    setFielddata("duplicate_h1") 
    else if(fielddata==19)
    setFielddata("multiple_h1") 
    else if(fielddata==20)
    setFielddata("h1_tags_available")
    else if(fielddata==17)
    setFielddata("no_index_robot") 
    else if(fielddata==8)
    setFielddata("pagination_prev_or_next") 
    else if(fielddata==9)
    setFielddata("hreflang") 

  },[fielddata])
  useEffect(() => {
 
    setMenubutval(domain_id)  
    setButtnval(domain_id)
    faqdatafun()
    
  }, []);
  const apihandleClose = () => {
    setapiOpen(false);
  };
 const reportNow = async () => {
    setLoading(true);
    var errs = 1;
    let errors = {};
    if (selectval === "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs === 2) {
      return setValidation(errors);
    }
    setjobidData(selectval[0].id);
    closeModal();
  };
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const pdfdown = async () =>{ 
    setLoading(true);
    try{
    const response = await fetch(`${process.env.REACT_APP_API_URL}technical/onpagecrawl/?user_domain_id=${esodata.domaindata.value.header_selected}&field_type=${fielddata}&is_format=pdf`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();

                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
               
                var filedata=await Commonfun("onPagecrawl","pdf", ApiToken,esodata.domaindata.value.header_selected);

                link.download = filedata;
                link.click(); 

                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              if(response?.status==599 || response?.status==500 || response?.status==400  || response?.status==404 ){
                response.json().then(data => {
                  setapiOpen(true);
                  setapierrorMsg(data?.error)
                  console.log('Resolved object:', data.error);
                })
              }
              else if(response?.code =="ERR_NETWORK"){
                navigate('/errorpages')    
              }
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);

  }
  const csvdown = async () =>{ 
    setLoading(true);
    try{
    const response = await fetch(`${process.env.REACT_APP_API_URL}technical/onpagecrawl/?user_domain_id=${esodata.domaindata.value.header_selected}&field_type=${fielddata}&is_format=csv`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();

                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
              
                var filedata=await Commonfun("onPagecrawl","csv", ApiToken,esodata.domaindata.value.header_selected);

                link.download = filedata;
                link.click(); 

                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              if(response?.status==599 || response?.status==500 || response?.status==400  || response?.status==404 ){
                response.json().then(data => {
                  setapiOpen(true);
                  setapierrorMsg(data?.error)
                  console.log('Resolved object:', data.error);
                })
              }
              else if(response?.code =="ERR_NETWORK"){
                navigate('/errorpages')    
              }
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);
  }
  const renderSwitch = (params) =>{  
    switch(params){
      case "1":
        return <Siteauditlists modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} onbutnvaldata={setButtnclickval} setJobdate={setJobdate} jobDate={jobDate}/>
      case "2":
        return <Onpagecrawllist modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} fielddata={fielddata} setFielddata={setFielddata}/>       
      case "3":
        return <Pagehealthlists modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}/>
      case "4":
        return <CompetitorTest modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}/>
    }
  } 
  const onpageref=useRef(null)
  const [RunReportPopShow,SetRunReportPopShow]=useState(false)
  const [RunReportType,SetRunReportType]=useState('site_audit')
  const e = document.getElementById('root');
  // e.addEventListener('click', function handleClick(event) {
  //   if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') || 
  //   (event.target.classList[0] === 'nav_key_txt') || 
  //   (event.target.classList[0] === 'cnt_hdr_top_wrap') ||(event.target.classList[0] === 'react-dropdown-select') 
  //   ||(event.target.classList[0] === 'react-dropdown-select-content') 
  //   ||(event.target.classList[0] === 'react-dropdown-select-item') 
  //   || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap')   || (event.target.classList[0] === 'App')  
  //   || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
  //    || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row') 
  //    || (event.target.classList[0] === 'MuiDataGrid-cell')  || (event.target.classList[0] === 'MuiDataGrid-cellContent')
  //    || (event.target.classList[0] === 'header_inner_btm_wrap')||(event.target.classList[0] === 'over_all_container') 
  //    ||(event.target.classList[0] === 'input_domain_field') ||(event.target.classList[0] === 'react-dropdown-select-input')
  //    || (event.target.classList[0] === 'run_rpt_btn')  || (event.target.classList[0] === 'input_col') || (event.target.classList[0] === 'plagarism_analyzer_row')
  //    || (event.target.classList[0] === 'MuiDataGrid-cell--withRenderer') ||  (event.target.classList[0] === 'link_rn_rpt_btn')
  //    || (event.target.classList[0] === 'drop-organicsearch') ||  (event.target.classList[0] === 'link_rn_rpt_btn')
  //   || (event.target.classList[0] ==='MuiButtonBase-root') || (event.target.classList[0]==='sitedataheader')
  //   || (event.target.classList[0] ==="onpage_button_active") || (event.target.classList[0]==='listdropdown_drop_site_onpage')
  //   ) {
     
  //     setfaqData(2)
  //     setToggle(false)   
  //   }
  // });
  const faqdatafun = async(menubutval="") => {
  
    var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=15`
  
  
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (doaminsectiondatavalue.data) {
       console.log("domain data",doaminsectiondatavalue?.data?.faqs)
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) {}
  }
  const handleToggle = () => {
    if(faqData!=1){
      setfaqData(1)
    }
    else{
      setfaqData(2)
    }
        setToggle((pre) => !pre);
  };
  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
       {loading === true ? <Loader /> : null}
        <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
<div
        style={{
          width: "calc(100% - 40px)",
          marginRight: "40px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
    <div className="cnt_audit_outer_wrap">
      <div className="cnt_hdr_top_wrap">
        <div className="cnt_hdr_top_lft_wrap">
         <h2> {pagename}</h2>
        </div>
        <div className="cnt_hdr_top_rgt_wrap">
        <div className={`App ${faqData=="1"?'faq_move_class':""}`} >
            <button onClick={handleToggle} className="faq_class"  title="Frequently Asked Question">
            <QuestionMarkIcon />
              
            </button>
           {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />} 
          </div>
        <img src={pdficon} alt="pdf icon" onClick={pdfdown}/>
          <img src={csvicon} alt="csv icon" onClick={csvdown} />
        </div>
      </div>

      <div className="site_cnt_hdr_blw_wrap">
      <Link to="/site-audit/1/" ><button  type="button" id={1}  className= {`site_rn_rpt_btn ${buttnval==="1"?'active':""}`}  >Site Audit</button></Link>

      <Link to="/onpage-crawl-data/2/1" > <button  type="button"  ref={onpageref} id={2} className={`site_rn_rpt_btn ${buttnval==="2"?'active':""}`}  >On Page Crawl Data</button></Link>

      <Link to="/page-health/3/" > <button  type="button" id={3} className={`site_rn_rpt_btn ${buttnval==="3"?'active':""}`} >Page Health</button></Link>

      <Link to="/competitor-test/4/" ><button  type="button" id={4} className={`site_rn_rpt_btn ${buttnval==="4"?'active':""}`}  >Competitor Analysis</button></Link>
        {/* <button  type="button" id={1}  className= {`site_rn_rpt_btn ${buttnval==="1"?'active':""}`} onClick={e => menuChange(e)} >Site Audit</button>
        <button  type="button"  ref={onpageref} id={2} className={`site_rn_rpt_btn ${buttnval==="2"?'active':""}`}  onClick={e => menuChange(e)} >On Page Crawl Data</button>
        <button  type="button" id={3} className={`site_rn_rpt_btn ${buttnval==="3"?'active':""}`}  onClick={e => menuChange(e)}>Page Health</button>
        <button  type="button" id={4} className={`site_rn_rpt_btn ${buttnval==="4"?'active':""}`}   onClick={e => menuChange(e)} >competitor-test</button> */}
      </div>

      <div className="site_blw_wrap">
        <div className="cnt_hdr_blw_wrap">
          <button className="run_rpt_btn rn_rpt_btn"  onClick={()=>{SetRunReportPopShow(true)}}>
            Run Report Now
          </button>
          <button className="run_rpt_btn ch_date_btn" onClick={openModal}>
            Filter By
            <span className="_btn_icon_calender">
              <img src={calendarpng} alt="calender logo" />
            </span>
          </button>
        </div>
      </div>
      </div>
      </div>
      {renderSwitch(menubutval)} 
      <RunReportNow RunReportPopShow={RunReportPopShow} SetRunReportPopShow={SetRunReportPopShow} RunReportType={RunReportType}/>
      <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
  );
}
