import React, { useState, useEffect ,useContext} from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import axios from "axios";
import "./../style.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import Select from "react-dropdown-select";
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { faLaptopHouse } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";   
import { Link, useParams,useSearchParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Keywordbar from "./../Rightsidebar/Keywordbar";
import { AppContext } from "../../../Context/Context";
export default function VisibilityScoreDetails() {
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [value, onChange] = React.useState();
  const [valueOutside, onChangeOutsidde] = React.useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const esodata = useSelector((state) => state);
  const ApiToken=esodata.apiauthkey.value
  const [faqData,setfaqData]=useState("2")
  const [initialDateval, SetInitialDateval] = useState([]); 
  const [toggle, setToggle] = useState(false);
  const [dselectjobid,setdselectjobid]=useState([])

  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const handleToggle = () => {
    if(faqData!=1){
      setfaqData(1)
    }
    else{
      setfaqData(2)
    }
        setToggle((pre) => !pre);
  };
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') || 
    (event.target.classList[0] === 'nav_key_txt') || 
    (event.target.classList[0] === 'cnt_hdr_top_wrap') ||(event.target.classList[0] === 'react-dropdown-select') 
    ||(event.target.classList[0] === 'react-dropdown-select-content') 
    ||(event.target.classList[0] === 'react-dropdown-select-item') 
    || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap')   || (event.target.classList[0] === 'App')  
    || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
     || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row') 
     || (event.target.classList[0] === 'MuiDataGrid-cell')  || (event.target.classList[0] === 'MuiDataGrid-cellContent')
     || (event.target.classList[0] === 'header_inner_btm_wrap')||(event.target.classList[0] === 'over_all_container') 
     ||(event.target.classList[0] === 'input_domain_field') ||(event.target.classList[0] === 'react-dropdown-select-input')
     ||(event.target.classList[0] === 'run_rpt_btn') ||(event.target.classList[0] === 'urlcheck') 
     ||(event.target.classList[0] === 'link_cnt_hdr_blw_wrap') ||(event.target.classList[0] === 'MuiDataGrid-columnHeaderTitle') 
     ||(event.target.classList[0] === 'cols') ||(event.target.classList[0] === 'card_num_class') 
     ||(event.target.classList[0] === 'content_col') || (event.target.classList[0] ==='link_rn_rpt_btn') 
     || (event.target.classList[0] ==='css-b5h07o') || (event.target.classList[0] ==='site_regress_chart_info') 
      || (event.target.classList[0] ==='linechartdesign')
    ) {
      setfaqData(2)
      setToggle(false)   
    }
});
  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = `${process.env.REACT_APP_DOMAIN}source-code-detail/`;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const arr = [];

  useEffect(() => {
    callUserdataApi();
    faqdatafun()
  }, []);
  const faqdatafun = async(menubutval="") => {
    var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=4`
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (doaminsectiondatavalue.data) {
       
        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) {}
  }
  const columns = [
    {
      field: "id",
      headerName: "#",
      width: 170,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
 
    {
      field: "Keyword",
      headerName: "Keywords",
      width: 500,
      headerAlign: 'center',
      align:'center',
      sortable: false,
    },
    {
      field: "Position",
      headerName: "Position",
      width: 300,
      headerAlign: 'center',
      align:'center',
      sortable: false,
    },
    {
      field: "Visibility",
      headerName: "Visibility",
      width: 300,
      headerAlign: 'center',
      align:'center',
      sortable: false,
    },
  ];

  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "") => {


    setjobDate('')
    setCardData([])
    setLoading(true);

    var StateId='';
    var CityId=''

    if(State.value)
    {
      StateId=State.value
    
    }
    if(City.value)
    {
      CityId=City.value
    }


    
    try{

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?user_domain_id=${esodata.domaindata.value.header_selected}&system=${System}&campaign_id=${campaign}&search_engine_id=${SearchEngine}&country_id=${Country}&state_id=${StateId}&city_id=${CityId}&job_id=${selectjobid}&devices=${Device}&rank_type=visibility_data`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
     
  
      const dataval = datavalue?.data?.result;
      if(dataval)
      {
        const newData = dataval.map((obj, index) => {
          return { ...obj, id: index+1 };
        });
  
        setCardData(newData);
        SetAutoheightval(false)
      }
      else{
        setCardData("");
        SetAutoheightval(true)
    }
   
     
      setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));

    }catch(err)
    {
      console.log(err)
    }

    setLoading(false);
    closeModal()
  };

  const reportNow = async () => {
    setLoading(true);
    var errs = 1;
    let errors = {};

    if (selectval == "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }
    setjobidData(selectval[0].id);
    closeModal();
    callUserdataApi(selectval[0].id);
  };
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const [ErrorMsg, SetErrorMsg] = useState("");
  const [Countryoptions, SetCountryoptions] = useState([]);
  const [Stateoptions, SetStateoptions] = useState([]);
  const [CityOptions, SetCityOptions] = useState([]);
  const [Engineoptions, SetEngineoptions] = useState([]);
  const [campaignoption, Setcampaignoption] = useState([]);


  let querystring = useParams();


  const [searchParams] = useSearchParams();

searchParams.get('campaign_id')
 

  const [selectjobid, setselectjobid] = useState(searchParams.get('job_id'));
  const [SearchEngine, SetSearchEngine] = useState(searchParams.get('search_engine_id'));
  const [Country, SetCountry] = useState(searchParams.get('country_id'));
  const [State, SetState] = useState({value:searchParams.get('state_id')});
  const [City, SetCity] = useState({value:searchParams.get('city_id')});
  const [System,SetSystem]=useState(searchParams.get('system'));
  const [Device,SetDevice]=useState(searchParams.get('devices'))
  const [campaign, Setcampaign] = useState(searchParams.get('campaign_id'));
  const [autoheightval,SetAutoheightval]=useState(true)

  const LoadAddFormFilterOptions = async (jobid = "") => {
    setjobidData([]);

    SetCountryoptions([]);
    SetEngineoptions([]);
    Setcampaignoption([])


    var StateId='';
    var CityId=''

    if(State.value)
    {
      StateId=State.value
    
    }
    if(City.value)
    {
      CityId=City.value
    }

    try {
      const campaigndatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${selectjobid}&user_domain_id=${esodata.domaindata.value.header_selected}&search_engine_id=${SearchEngine}&country_id=${Country}&state_id=${StateId}&city_id=${CityId}&filter_type=campaign`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (campaigndatavalue.data.campaigns) {
        if (campaigndatavalue.data.campaigns) {
          var campaignSections = [];
          campaigndatavalue.data.campaigns.forEach((element) => {
            campaignSections.push({ label: element.category__name, value: element.category__id });
          });
          Setcampaignoption(campaignSections);
        }
      }
    } catch (err) {}
    try {
      const jobdatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?user_domain_id=${esodata.domaindata.value.header_selected}&job_type=rankings_summary`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (jobdatavalue.data.jobs) {
        if (jobdatavalue.data.jobs) {
          var jobSections = [];
          jobdatavalue.data.jobs.forEach((element) => {
            jobSections.push({ label: element.completed_at, value: element.id });
          });
          setdselectjobid(jobSections[0])

          setjobidData(jobSections);
        }
      }
    } catch (err) {}
    try {
      const enginedatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${jobid}&user_domain_id=${esodata.domaindata.value.header_selected}&campaign_id=${campaign}&country_id=${Country}&state_id=${StateId}&city_id=${CityId}&filter_type=search_engine`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (enginedatavalue.data.search_engines) {
        if (enginedatavalue.data.search_engines) {
          var Sections = [];
          enginedatavalue.data.search_engines.forEach((element) => {
            Sections.push({ label: element.search_engine_locale__search_engine__name, value: element.search_engine_locale__search_engine__id });
          });
          SetEngineoptions(Sections);
        }
      }
    } catch (err) {}
    try {
      const countrydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${jobid}&user_domain_id=${esodata.domaindata.value.header_selected}&campaign_id=${campaign}&search_engine_id=${SearchEngine}&state_id=${StateId}&city_id=${CityId}&filter_type=country`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (countrydatavalue.data.countries) {
        if (countrydatavalue.data.countries) {
          var Sections = [];
          countrydatavalue.data.countries.forEach((element) => {
            Sections.push({ label: element.search_engine_locale__country__name, value: element.search_engine_locale__country__id });
          });
          SetCountryoptions(Sections);
        }
      }
    } catch (err) {}



  };

  const SetCountryHandle = async (value) => {
    SetStateoptions([]);


    var StateId='';
    var CityId=''

    if(State.value)
    {
      StateId=State.value
    
    }
    if(City.value)
    {
      CityId=City.value
    }


    if (value) {
      SetCountry(value.value);
      try {
        const statedatavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${selectjobid}&user_domain_id=${esodata.domaindata.value.header_selected}&campaign_id=${campaign}&search_engine_id=${SearchEngine}&country_id=${value.value}&filter_type=state`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        );
        if (statedatavalue.data) {
          if (statedatavalue.data.states) {
            var Sections = [];
            statedatavalue.data.states.forEach((element) => {
              Sections.push({
                label: element.search_engine_locale__state__state_name,
                value: element.search_engine_locale__state__id,
              });
            });
            SetStateoptions(Sections);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const SetStateHandle = async (value) => {
    SetCityOptions([]);
    if (value) {
      SetState(value);

      const citydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?job_id=${selectjobid}&user_domain_id=${esodata.domaindata.value.header_selected}&campaign_id=${campaign}&search_engine_id=${SearchEngine}&country_id=${Country}&state_id=${value.value}&filter_type=city`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );

      if (citydatavalue.data) {
        if (citydatavalue.data.cities) {
          var Sections = [];
          citydatavalue.data.cities.forEach((element) => {
            Sections.push({
              label: element.search_engine_locale__city__city_name,
              value: element.search_engine_locale__city__id,
            });
          });
          SetCityOptions(Sections);
        }
      }
    }
  };

  const finalreportNow=()=>{
   callUserdataApi()
  }

  useEffect(()=>{
LoadAddFormFilterOptions()
  },[])

  const pdfdown = async () =>{ 

    setLoading(true);
    try{
    const response = await fetch(`${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?user_domain_id=${esodata.domaindata.value.header_selected}&system=${System}&campaign_id=${campaign}&search_engine_id=${SearchEngine}&country_id=${Country}&state_id=${State.value}&city_id=${City.value}&job_id=${selectjobid}&devices=${Device}&rank_type=visibility_data&formats=pdf`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();
  
                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'VisibilityScoreDetail.pdf';
  
                // Trigger a click on the anchor element to start the download
                link.click();
  
                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              toast.error("No Data", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                console.error('Failed to fetch PDF:', response.status, response.statusText);
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);
  
  }
  const csvdown = async () =>{ 
    setLoading(true);
    try{
      const response = await fetch(`${process.env.REACT_APP_API_URL}keywords/v2/ranking_summary_table_report/?user_domain_id=${esodata.domaindata.value.header_selected}&system=${System}&campaign_id=${campaign}&search_engine_id=${SearchEngine}&country_id=${Country}&state_id=${State.value}&city_id=${City.value}&job_id=${selectjobid}&devices=${Device}&rank_type=visibility_data&formats=csv`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();
  
                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'VisibilityScoreDetail.csv';
  
                // Trigger a click on the anchor element to start the download
                link.click();
  
                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              toast.error("No Data", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                console.error('Failed to fetch PDF:', response.status, response.statusText);
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);
  }

  return (
  <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
        <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Visiblity Score Details</title>
            </Helmet>
            <div
              style={{
                width: "calc(100% - 40px)",
                marginRight: "40px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
    <div className="cnt_audit_outer_wrap">
      <div className="cnt_hdr_top_wrap">
        <div className="cnt_hdr_top_lft_wrap">
          <h2>Ranking Visiblity Score</h2>
          <p>as of {jobDate}</p>
        </div>
        <div className="cnt_hdr_top_rgt_wrap">
        <div className={`App ${faqData=="1"?'faq_move_class':""}`} >
            <button onClick={handleToggle} className="faq_class"  title="Frequently Asked Question">
            <QuestionMarkIcon />
              
            </button>
           {toggle && <Keywordbar close={() => setToggle(false)} initialDateval={initialDateval} />} 
          </div>
        <img src={pdficon} alt="pdf icon" onClick={pdfdown}/>
          <img src={csvicon} alt="csv icon"  onClick={csvdown}/>
        </div>
      </div>

      <div className="cnt_hdr_blw_wrap">
        
        <button className="run_rpt_btn ch_date_btn" onClick={openModal}>
          Filter By
          <span className="_btn_icon_calender">
            <img src={calendarpng} alt="calender logo" />
          </span>
        </button>
      </div>
      <div className="data_table code_audit_table" style={{ width: "100%" }}>
        <div style={{ height: 500, width: "100%" }}>
          <DataGrid
          
            slots={{
              noRowsOverlay: MyCustomNoRowsOverlay,
            }}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "8px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "22px",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal",
              },
              "&, [class^=MuiDataGrid]": { border: "none" },
              ".MuiDataGrid-virtualScroller": {
                height: "auto !important",
                overflowY: "auto",
              },
              "& .MuiDataGrid-cell:first-child": {
                position: "sticky",
                top: "0",
                left: "0",
                paddingLeft: "1.5rem",
                zIndex: 999,
              },
              "& .MuiDataGrid-columnHeader:first-child": {
                position: "sticky",
                top: "0",
                left: "0",
                paddingLeft: "1.5rem",
                border: "none",
                zIndex: 999,
              },
            }}
            rows={rows}
            columns={columns}
            getRowHeight={() => 'auto'}
            autoHeight={autoheightval?true:false}  
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 30, 50, 75, 100]}
            disableColumnFilter
            loading={loading}
            disableColumnSelector
            disableDensitySelector
          />
        </div>
      </div>
      <Dialog
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter"
      >
        <DialogTitle>{"Filter"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="error_plagarism_report">{ErrorMsg}</div>
            <div className="four_col">
              {/* <div className="col">
                <label className="form-label">Campaign</label>
                <Select
                  options={campaignoption}
                  onChange={(e) => {
                    Setcampaign(e[0].value);
                  }}
                />
              </div> */}
              <div className="col">
                <label className="form-label">Job Id</label>
                <Select
                 values ={[dselectjobid]}
                  defaultValue={[dselectjobid][0]}
                  options={jobidData}
                  onChange={(e) => {
                    setselectjobid(e[0].value);
                    setdselectjobid({ label: e[0]?.label, value: e[0]?.value })
                  }}
                />
              </div>
              {/* <div className="col">
                <label className="form-label">Search Engine</label>
                <Select
                  options={Engineoptions}
                  onChange={(e) => {
                    SetSearchEngine(e[0].value);
                  }}
                />
              </div>
              <div className="col">
                <label className="form-label">Country</label>
                <Select
                  options={Countryoptions}
                  onChange={(e) => {
                    SetCountryHandle(e[0]);
                  }}
                />
              </div>
              <div className="col">
                <label className="form-label">State</label>
                <Select
                  options={Stateoptions}
                  onChange={(e) => {
                    SetStateHandle(e[0]);
                  }}
                />
              </div>
              <div className="col">
                <label className="form-label">City</label>
                <Select
                  options={CityOptions}
                  onChange={(e) => {
                    SetCity(e[0]);
                  }}
                />
              </div>

              <div className="col">
                <label className="form-label">System</label>

                <input type="radio" name="system" value={'mobile'} onChange={(e)=>{SetSystem(e.target.value)}} />Mobile
                <input type="radio" name="system" value={'desktop'} onChange={(e)=>{SetSystem(e.target.value)}}/>Desktop
            
              </div>

              {
                 System==='mobile' ?     <div className="col">
                 <label className="form-label">Devices</label>
 
                 <input type="radio" name="devices" value={'android'} onChange={(e)=>{SetDevice(e.target.value)}} />Android
                 <input type="radio" name="devices" value={'ios'} onChange={(e)=>{SetDevice(e.target.value)}}/>Ios
             
               </div> : null
              }

           */}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="run_rpt_btn rn_rpt_btn"
            onClick={finalreportNow}
            style={{ cursor: "pointer" }}
          >
            Submit
          </button>
          <button
            className="run_rpt_btn rn_rpt_btn"
            style={{ cursor: "pointer" }}
            onClick={closeModal}
          >
            close
          </button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Error Message "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            No Data Found
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
    </div>
  </div>
  );
}
