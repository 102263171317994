import React, { useEffect, useState,useContext } from "react";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Tooltip } from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";
import Loader from "../CommonLoader";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import InprogressIcon from "../../../Icons/work-in-progress-unscreen.gif";
import LinkIcon from "@mui/icons-material/Link";
import moment from "moment";
import calendarpng from "../Asset/New_Assert/ESO_Icon/Filter.svg";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "react-dropdown-select";
import fav_icon from "../Asset/New_Assert/ESO_ICON.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from 'react-helmet';
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { AppContext } from "../../../Context/Context";


const SiteMapGenerator = () => {
  const [AnalyzeUrl, SetAnalyzeUrl] = useState("");
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);
  const [XmlYes, SetXmlYes] = useState(true);
  const [HtmlYes, SetHtmlYes] = useState(true);
  const [ImageYes, SetImageYes] = useState(true);
  const [VideoYes, SetVideoYes] = useState(true);
  const [ShowSetttings, SetShowSetttings] = useState(false);
  const [ChangeFrequency, SetChangeFrequency] = useState("");
  const [InclusionDomains, SetInclusionDomains] = useState("");
  const [SiteProtected, SetSiteProtected] = useState("no");
  const [SiteUserName, SetSiteUserName] = useState("");
  const [SitePassword, SetSitePassword] = useState("");
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState([]);
  const rows = cardData ?? [];
  const [jobDate, SetjobDate] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [autoheightval,SetAutoheightval]=useState(true)
  const [faqData,setfaqData]=useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval,SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();
  const [initialDateval, SetInitialDateval] = useState([]);
  
  const navigate = useNavigate();
  function closeModal() {
    setSelectval("");
    setIsOpen(false);
  }
  const openModal = () => {
    setIsOpen(true);
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };
  useEffect(() => {
    var DomainList = esodata.domainlist.value;
    DomainList = DomainList.find(
      (obj) => obj.id == esodata.domaindata.value.header_selected
    );
    if (DomainList) {
      SetAnalyzeUrl(DomainList.domain__url);
    }
    faqdatafun();
  }, [esodata]);

  const columns = [
    {
      field: "sid",
      headerName: "#",
      description: "Index of the row",
      width: 70,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "url",
      headerName: "Url",
      description: "List Of The Website's URLs",
      width: 260,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        return (
          <>
            <Tooltip title={cardData.row.url}>
              <div className="prefered_url_external">
                <LinkIcon />
                <a target="_blank" href={cardData.row.url}>
                  {cardData.row.url}
                </a>
              </div>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "type_of_sitemap",
      headerName: "Type of sitemap",
      description: "The Type of Sitemap Generated for the Domain",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        if (cardData.row.type_of_sitemap !== null) {
          return cardData.row.type_of_sitemap;
        } else {
          return "-";
        }
      },
    },
    {
      field: "date_of_request",
      headerName: "Date of request",
      description: "The Date On Which the Sitemap Was Generated",
      width: 100,
      headerAlign: "center",
      sortable: false,
      align: "center",
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        if (cardData.row.date_of_request !== null) {
          return cardData.row.date_of_request;
        } else {
          return "-";
        }
      },
    },
    {
      field: "total_urls",
      headerName: "Total Url's",
      description: "The Total No. Of URLs Crawled For The Domain",
      width: 120,
      headerAlign: "center",
      sortable: false,
      align: "center",
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        if (cardData.row.total_urls !== null) {
          return cardData.row.total_urls;
        } else {
          return "-";
        }
      },
    },
    {
      field: "valid_urls",
      headerName: "Valid Url's",
      description: "The Total No. Of Valid URLs Crawled For The Domain",
      width: 120,
      headerAlign: "center",
      sortable: false,
      align: "center",
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        if (cardData.row.valid_urls !== null) {
          return cardData.row.valid_urls;
        } else {
          return "-";
        }
      },
    },
    {
      field: "invalid_urls",
      headerName: "Invalid Url's",
      description: "The Total No. Of Invalid URLs Crawled For The Domain",
      width: 120,
      headerAlign: "center",
      sortable: false,
      align: "center",
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        if (cardData.row.invalid_urls !== null) {
          return cardData.row.invalid_urls;
        } else {
          return "-";
        }
      },
    },
    {
      field: "change_in_urls",
      headerName: "Change In Url's",
      description: "The change of URL count comparing the previously generated Sitemap and recently generated Sitemap.",
      width: 120,
      headerAlign: "center",
      sortable: false,
      align: "center",
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        if (cardData.row.change_in_urls !== null) {
          return cardData.row.change_in_urls;
        } else {
          return "-";
        }
      },
    },
    {
      field: "total_time_to_generate",
      headerName: "Total time to generate",
      description: "The Totaltime Taken To Generate The Specific Type Of Sitemap For The Domain",
      width: 120,
      headerAlign: "center",
      sortable: false,
      align: "center",
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        let duration = moment.duration(cardData.row.total_time_to_generate);

        if (cardData.row.total_time_to_generate !== null) {
          // Extract minutes and seconds
          let minutes = duration.minutes();
          let seconds = duration.seconds();
          let humanReadableTime = `${minutes} minutes and ${seconds} seconds`;

          return humanReadableTime;
        } else {
          return "-";
        }
      },
    },
    {
      field: "status",
      headerName: "Status",
      description: "Clicking On The Download Button Will Allow You To Download The Specific Type Of Sitemap For The Domain.",
      width: 150,
      headerAlign: "center",
      sortable: false,
      align: "center",
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        if (cardData.row.status == true) {
          return (
            <div>
              <button
                id={cardData.row.id}
                onClick={DownloadSitemap}
                className="buttonView"
              >
                Download
                <img
                  src={fav_icon}
                  className="view_std_img"
                  alt="view report icon"
                />
              </button>
            </div>
          );
        } else if (cardData.row.status == false) {
          return (
            <div className="in_table_progress_icon_wrap">
              <div>In Progress</div>
              <div>
                <img className="in_table_progress_icon" src={InprogressIcon} />{" "}
              </div>
            </div>
          );
        }
      },
    },
  ];

  const DownloadSitemap = async (e) => {


    const Id = e.target.closest(".buttonView").getAttribute("id");
   
    setLoading(true)
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}sitemap_generator/download_sitemap_generator_file/?user_domain_id=${esodata.domaindata.value.header_selected}&pk_id=${Id}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
     
      if(datavalue?.headers['content-type'])
      {
        
        var String=datavalue.headers['content-type'];
         String = String.split('/');
         String=String[1]
       

        var pseudoelement = document.createElement("a");
        var theMoment = moment();

        var filename = "report-"+theMoment+"."+String;
        var pseudoelement = document.createElement("a");
        var blob = new Blob([datavalue.data], { type: datavalue.headers['content-type'] });
      
        pseudoelement.setAttribute("href", window.URL.createObjectURL(blob));
        pseudoelement.setAttribute("download", filename);
      
        pseudoelement.dataset.downloadurl = [datavalue.headers['content-type'], pseudoelement.download, pseudoelement.href].join(":");
        pseudoelement.draggable = true;
        pseudoelement.classList.add("dragout");
      
        pseudoelement.click();
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      toast.error('Download has been failed!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  };

  const TriggerSettings = () => {
    SetShowSetttings(!ShowSetttings);
  };

  const AnalyzeReport = async () => {
    setLoading(true);


    try {
      const Response = await axios.post(
        `${process.env.REACT_APP_API_URL}sitemap_generator/generate_sitemap/`,
        {
          xml_sitemap: XmlYes,
          html_sitemap: HtmlYes,
          images: ImageYes,
          videos: VideoYes,
          request_url: AnalyzeUrl,
          changefrq: ChangeFrequency,
          Is_site_protected: SiteProtected,
          username: SiteUserName,
          password: SitePassword,
          user_domain_id: `${esodata.domaindata.value.header_selected}`,
        },
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });

      if (Response?.data?.status) {
        setLoading(false);
  
        toast.error(Response.data.status, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      } else {
        setTimeout(() => {
          setLoading(false);
          GetTableReport();
        }, 2000);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const GetTableReport = async (JobId = "") => {
    setLoading(true);
    setCardData([]);

    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}sitemap_generator/sitemap_generator_table/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      if (datavalue?.data) {
        if (datavalue?.data?.table_data) {
          SetAutoheightval(false)
          if(datavalue?.data?.job_date)
          {
           
            SetjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));
          }
          else if(datavalue?.data?.table_data)
          {
            SetjobDate(moment(datavalue.data.table_data[0].date_of_request).format("MMMM DD,YYYY HH:mm"));
          }
  


          const newData = datavalue.data.table_data.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });

          setCardData(newData);
        }
      }
      else{
        setCardData("");
      SetAutoheightval(true)
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );

  const reportNow = () => {
    if (selectval !== "") {
      if (selectval.length > 0) {
        var JobId = "";
        JobId = selectval[0].id;
        if (JobId !== "") {
          GetTableReport(JobId);
          closeModal();
        }
      }
    }
  };

  const GetJobDates = async () => {
    setcompletedate([]);

    try{

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?user_domain_id=${esodata.domaindata.value.header_selected}&job_type=sitemap_generator`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      var Selectedmets = [];
      if (datavalue?.data) {
        if (datavalue?.data?.jobs) {
          datavalue?.data?.jobs.forEach((element) => {
            Selectedmets.push({
              id: element.id,
              completed_at: element.completed_at,
            });
          }); 
          SetInitialDateval(Selectedmets[0]);
          setSelectval(Selectedmets[0].value);
          SetdEngineoption(Selectedmets[0]);
          setcompletedate(Selectedmets);
        }
      }

    }catch(err)
    {
      console.log(err)
    }

  
  };

  useEffect(() => {
    GetTableReport();
    GetJobDates();
  }, []);

  const [dEngineoption, SetdEngineoption] = useState({
    completed_at:
      'Select Date',
      id:
      'Select Date',
  });
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') || 
    (event.target.classList[0] === 'nav_key_txt') || 
    (event.target.classList[0] === 'cnt_hdr_top_wrap') ||(event.target.classList[0] === 'react-dropdown-select') 
    ||(event.target.classList[0] === 'react-dropdown-select-content') 
    ||(event.target.classList[0] === 'react-dropdown-select-item') 
    || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap')   || (event.target.classList[0] === 'App')  
    || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
     || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row') 
     || (event.target.classList[0] === 'MuiDataGrid-cell')  || (event.target.classList[0] === 'MuiDataGrid-cellContent')
     || (event.target.classList[0] === 'header_inner_btm_wrap')||(event.target.classList[0] === 'over_all_container') 
     ||(event.target.classList[0] === 'input_domain_field') ||(event.target.classList[0] === 'react-dropdown-select-input')
     || (event.target.classList[0] === 'run_rpt_btn')  || (event.target.classList[0] === 'input_col') || (event.target.classList[0] === 'plagarism_analyzer_row')
    ) {
     
      setfaqData(2)
      setToggle(false)   
    }
  });
  const faqdatafun = async(menubutval="") => {
    var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=42`
  
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {
       console.log("domain data",doaminsectiondatavalue?.data?.faqs)
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) {}
  }
  const handleToggle = () => {
    if(faqData!=1){
      setfaqData(1)
    }
    else{
      setfaqData(2)
    }
        setToggle((pre) => !pre);
  };
  return (
    <>
           <Helmet>
                <meta charSet="utf-8" />
                <title>Site Map Generator</title>
            </Helmet>
      <div className={`over_all_container sitemap_generator ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
        {loading === true ? <Loader /> : null}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="cnt_audit_outer_wrap">
          <div className="cnt_hdr_top_wrap">
            <div className="cnt_hdr_top_lft_wrap">
              <h2>Sitemap Generator</h2>
              <p>as of {jobDate}</p>
            </div>
            <div className="cnt_hdr_top_rgt_wrap">
            <div className={`App ${faqData=="1"?'faq_move_class':""}`} >
            <button onClick={handleToggle} className="faq_class"  title="Frequently Asked Question">
            <QuestionMarkIcon />
              
            </button>
           {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />} 
          </div>
              {/* <img src={pdficon} alt="pdf icon" />
              <img src={csvicon} alt="csv icon" /> */}
            </div>
          </div>

          {/* <div className="cnt_hdr_blw_wrap"> */}
            {/* <button className="run_rpt_btn rn_rpt_btn">Run Report Now</button> */}
            {/* <button className="run_rpt_btn ch_date_btn" onClick={openModal}>
              Filter By
              <span className="_btn_icon_calender">
                <img src={calendarpng} alt="calender logo" />
              </span>
            </button> */}
          {/* </div> */}

          <div className="plagarism_analyzer_row no_pad">
            <div className="input_col">
              <input
                type="text"
                onChange={(e) => {
                  SetAnalyzeUrl(e.target.value);
                }}
                defaultValue={AnalyzeUrl}
                placeholder="Enter URL to generate"
                disabled
                style={{"backgroundColor":"white"}}
              />
            </div>
            <div className="button_col">
              <button class="run_rpt_btn rn_rpt_btn mb-2" onClick={AnalyzeReport}>
                Generate
              </button>
              <button class="run_rpt_btn rn_rpt_btn" onClick={TriggerSettings}>
                Settings
              </button>
            </div>
          </div>
          <div
            className="plagarism_analyzer_row no_pad"
            style={{ color: "red" }}
          >
         
          </div>

          {ShowSetttings == true ? (
            <div className="row settings_form_row">
              <div className="type_of_settings">
                <div className="typecol">
                  <input
                    type="checkbox"
                    defaultChecked={XmlYes}
                    onChange={(e) => {
                      SetXmlYes(e.target.checked);
                    }}
                  />
                  XML Sitemap{" "}
                  <Tooltip title={"list of the website's URLs as an XML file"}>
                    <QuestionMarkIcon />
                  </Tooltip>
                </div>

                <div className="typecol">
                  <input
                    type="checkbox"
                    defaultChecked={HtmlYes}
                    onChange={(e) => {
                      SetHtmlYes(e.target.checked);
                    }}
                  />
                  HTML Sitemap
                  <Tooltip
                    title={
                      "An HTML sitemap allows site visitors to easily navigate a website"
                    }
                  >
                    <QuestionMarkIcon />
                  </Tooltip>
                </div>

                <div className="typecol">
                  <input
                    type="checkbox"
                    defaultChecked={ImageYes}
                    onChange={(e) => {
                      SetImageYes(e.target.checked);
                    }}
                  />
                  Image Sitemap
                  <Tooltip
                    title={
                      "Image siteamp includies imaeg URLs in the sitemap to avoid repeating  images acorss your pages"
                    }
                  >
                    <QuestionMarkIcon />
                  </Tooltip>
                </div>

                <div className="typecol">
                  <input
                    type="checkbox"
                    defaultChecked={VideoYes}
                    onChange={(e) => {
                      SetVideoYes(e.target.checked);
                    }}
                  />
                  Video Sitemap
                  <Tooltip
                    title={
                      "A video sitemap provides additional information about any video content hosted on the webpage"
                    }
                  >
                    <QuestionMarkIcon />
                  </Tooltip>
                </div>
              </div>

              <div className="settings_form">
                <div className="form_field_row">
                  <div className="label_col">
                    Change Frequency{" "}
                    <Tooltip
                      title={"how frequently content is likely to change"}
                    >
                      <QuestionMarkIcon />
                    </Tooltip>
                  </div>
                  <div className="field_col">
                    <select
                      defaultValue={ChangeFrequency}
                      onChange={(e) => {
                        SetChangeFrequency(e.target.value);
                      }}
                    >
                      <option value={""}>Select Frequency</option>
                      <option value={"Monthly"}>Monthly</option>
                      <option value={"Always"}>Always</option>
                      <option value={"Hourly"}>Hourly</option>
                      <option value={"Daily"}>Daily</option>
                      <option value={"Weekly"}>Weekly</option>
                      <option value={"Yearly"}>Yearly</option>
                      <option value={"Never"}>Never</option>
                    </select>
                  </div>
                </div>

                {/* <div className="form_field_row">
                <div className="label_col">
                  Inclusion Domains{" "}
                  <Tooltip title={"XML Sitempa"}>
                    <QuestionMarkIcon />
                  </Tooltip>
                </div>
                <div className="field_col">
                  <textarea
                    onChange={(e) => {
                      SetInclusionDomains(e.target.value);
                    }}
                    rows="4"
                    cols="50"
                  ></textarea>
                </div>
              </div> */}

                <div className="form_field_row">
                  <div className="label_col">
                    Is site protected{" "}
                    <Tooltip
                      title={
                        "Site is protected with username and password authentication"
                      }
                    >
                      <QuestionMarkIcon />
                    </Tooltip>
                  </div>
                  <div className="field_col">
                    <input
                      type="radio"
                      name="site_protected"
                      value={"yes"}
                      defaultChecked={SiteProtected == "yes" ? true : false}
                      onChange={(e) => {
                        SetSiteProtected(e.target.value);
                      }}
                    />
                    Yes &nbsp;
                    <input
                      type="radio"
                      name="site_protected"
                      value={"no"}
                      defaultChecked={SiteProtected == "no" ? true : false}
                      onChange={(e) => {
                        SetSiteProtected(e.target.value);
                      }}
                    />
                    No
                  </div>
                </div>

                {SiteProtected === "yes" ? (
                  <div className="form_field_row">
                    <div className="label_col">
                      Credentials{" "}
                      <Tooltip
                        title={"Username and password to access the website"}
                      >
                        <QuestionMarkIcon />
                      </Tooltip>
                    </div>
                    <div className="field_col">
                      <div className="two_inputs">
                        <input
                          type="text"
                          defaultValue={SiteUserName}
                          placeholder="Username"
                          onChange={(e) => SetSiteUserName(e.target.value)}
                        />
                        <input
                          type="text"
                          defaultValue={SitePassword}
                          placeholder="Password"
                          onChange={(e) => {
                            SetSitePassword(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}

          <div className="data_table" style={{ width: "100%" }}>
            <div style={{ height: 550, width: "100%" }}>
              <DataGrid
                sx={{
                  "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                    py: "8px",
                  },
                  "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                    py: "15px",
                  },
                  "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                    py: "22px",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    whiteSpace: "normal",
                    lineHeight: "normal",
                  },
                  "&, [class^=MuiDataGrid]": { border: "none" },
                  ".MuiDataGrid-virtualScroller": {
                    height: "auto !important",
                    overflowY: "auto",
                  },
                  "& .MuiDataGrid-cell:first-child": {
                    position: "sticky",
                    top: "0",
                    left: "0",
                    paddingLeft: "1.5rem",
                    zIndex: 999,
                  },
                  "& .MuiDataGrid-columnHeader:first-child": {
                    position: "sticky",
                    top: "0",
                    left: "0",
                    paddingLeft: "1.5rem",
                    border: "none",
                    zIndex: 999,
                  },
                }}
                autoHeight={autoheightval?true:false} 
                getRowHeight={() => "auto"}
                getRowId={(row) => row.sid}
                slots={{
                  noRowsOverlay: MyCustomNoRowsOverlay,
                  toolbar: GridToolbar,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10, 30, 50, 75, 100]}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
              />
            </div>
          </div>
        </div>

        <Dialog
          open={modalIsOpen}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="competitor_test_filter job_date_filter"
        >
          <DialogTitle>{"Choose The Date"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="cnt_hdr_blw_wrap row">
                <div>
                  <div className="">
                    <div>
                      <span>Completed Date List</span>
                    
                      <Select
                        values={[dEngineoption]}
                        options={completedate}
                        labelField="completed_at"
                        valueField="id"
                        onChange={(values) =>{ setSelectval(values)

                          SetdEngineoption({
                            completed_at:
                              values[0].completed_at,
                              id:
                              values[0].id,
                          });
                        
                        }}
                      />
                    </div>
                  </div>

                  <div className="col">
                    <span className="text-error">{""}</span>
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              className="run_rpt_btn rn_rpt_btn"
              onClick={reportNow}
              style={{ cursor: "pointer" }}
            >
              Submit
            </button>
            <button style={{ cursor: "pointer" }} onClick={closeModal}>
              close
            </button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
export default SiteMapGenerator;
